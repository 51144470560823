import { AuthService } from "../services/AuthService";
import { programCatalogService } from "../services/index.service";
import { storage } from "../utilities/storage.utility";

export default {
  state: {
    loggedUser: null,
    isLoggedIn: false,
    authChecked: false,
  },
  reducers: {
    setLoggedUser(state, payload) {
      return {
        ...state,
        loggedUser: payload,
        isLoggedIn: !!payload,
        authChecked: true,
      };
    },
  },
  effects: (dispatch) => ({
    checkUser: async () => {
      let isAuth;
      let loggedInUser = null;
      if (window.apiClient.fromRegister) {
        isAuth = true;
        loggedInUser = await window.apiClient.setLoggedInUser({
          accessToken: window.apiClient.accessToken,
          refreshToken: window.apiClient.refreshToken,
        });
      } else {
        isAuth = await AuthService.Instance.isAuthenticated();

        if (isAuth) {
          const tokens = await window.AuthService.getTokenFromObserver();
          loggedInUser = await window.apiClient.setLoggedInUser(tokens);
        }
      }
      console.log(`Got isAuth ${isAuth}`);
      console.log("Got loggedInUser", loggedInUser);
      // const user = await AuthService.Instance.getUserInfo();
      // console.log(user);
      // await window.apiClient.setLoggedInUser(user);
      // return dispatch.authStore.setLoggedUser(user);
      return dispatch.authStore.setLoggedUser(loggedInUser);
    },
    loginUser: () => AuthService.Instance.signIn(),
    logoutUser: async () => {
      try {
        storage.clear();
        await AuthService.Instance.storage.clear();
        await AuthService.Instance.signOut();
        await programCatalogService.destroyDatabase();
      } catch (e) {
        console.error("Cannot logout via AuthService");
        console.error(e);
      }
      window.location.reload();
      // await window.db.hkapi.destroy();
      // authService.logout();
    },
  }),
};
