import React, { Component } from "react";
import { connect } from "react-redux";
import { IonPage, IonContent } from "@ionic/react";
import {
  Container,
  createStyles,
  Typography,
  withStyles,
} from "@material-ui/core";

import Header from "../../common/Header";

// FORMS
import UserVerification from "../../components/Forms/UserVerification.form";

//IMG
import VerifyImg from "../../img/verification.png";

const styles = ({ spacing, palette }) =>
  createStyles({
    verifyImg: {
      paddingRight: spacing(8),
      paddingLeft: spacing(8),
      paddingTop: spacing(4),
      paddingBottom: spacing(4),
    },
    verifyText: {
      textAlign: "center",
      marginBottom: spacing(4),
    },
  });

class VerificationPage extends Component {
  clickBackButton = () => {
    this.props.logoutUser();
  };

  render() {
    const { classes } = this.props;
    return (
      <IonPage>
        <Header
          title="Verification"
          backButton
          backButtonFunction={this.clickBackButton}
          searchButton={false}
        />
        <IonContent>
          <Container>
            <img
              src={VerifyImg}
              className={classes.verifyImg}
              alt="Mail Verification"
            />
            <Typography
              variant="caption"
              display="block"
              className={classes.verifyText}
            >
              Please check your email for verification code.
            </Typography>
            <UserVerification history={this.props.history} />
          </Container>
        </IonContent>
      </IonPage>
    );
  }
}

const mapDispatchToProps = ({ authStore }) => ({
  ...authStore,
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(VerificationPage));
