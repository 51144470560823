import React from "react";
import { connect } from "react-redux";
import {
  Container,
  Typography,
  Box,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { Markup } from "interweave";
import moment from "moment";

import LogoImg from "./../../img/logo-colored.png";

const styles = ({ spacing, palette }) =>
  createStyles({
    table: {
      border: "1px solid black",
      width: "100%",
    },
    tr: {
      border: "1px solid black",
    },
    th: {
      border: "1px solid black",
      padding: "2px",
      backgroundColor: grey[500],
      textTransform: "uppercase",
    },
    tdHeader: {
      border: "1px solid black",
      padding: "2px 5px",
      width: "35%",
      verticalAlign: "top",
    },
    tdContent: {
      border: "1px solid black",
      padding: "2px 5px",
      width: "65%",
      "& p": {
        margin: 0,
      },
      "& ul": {
        margin: 0,
        paddingLeft: "25px",
      },
    },
    logoBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    logo: {
      width: "30%",
    },
    text: {
      textAlign: "center",
      marginBottom: spacing(1),
    },
    header: {
      textAlign: "center",
      fontWeight: 700,
      marginTop: spacing(1),
      marginBottom: spacing(0.5),
      textTransform: "uppercase",
    },
  });

class PrintLayout extends React.Component {
  render() {
    const currentVersion = "v" + "1.0.0";
    const { classes } = this.props;
    const {
      brandDetails,
      selectedContactInfo,
      selectedMechanics,
    } = this.props.programCatalogStore;
    const currentDate = moment(brandDetails.updated_at).format("MMM DD YYYY");
    console.log(selectedMechanics);
    return (
      <Container className="with-print">
        <Box className={classes.logoBox}>
          <img src={LogoImg} className={classes.logo} />
        </Box>
        <Typography className={classes.header}>
          One Platform Directory
        </Typography>
        <Typography className={classes.text}>
          Last Updated {currentDate} | {currentVersion}
        </Typography>
        <Box>
          <table className={classes.table}>
            <tr className={classes.tr}>
              <th colSpan={2} className={classes.th}>
                Program Information
              </th>
            </tr>
            <tr className={classes.tr}>
              <td className={classes.tdHeader}>Brand Name (English/Chinese)</td>
              <td className={classes.tdContent}>{brandDetails.brand_name}</td>
            </tr>
            <tr className={classes.tr}>
              <td className={classes.tdHeader}>Generic Name (English)</td>
              <td className={classes.tdContent}>{brandDetails.generic_name}</td>
            </tr>
            <tr className={classes.tr}>
              <td className={classes.tdHeader}>NGO (Chinese)</td>
              <td className={classes.tdContent}>
                <Markup content={brandDetails.ngo} />
              </td>
            </tr>
            <tr className={classes.tr}>
              <td className={classes.tdHeader}>
                NGO Contact Details (Chinese)
              </td>
              <td className={classes.tdContent}>
                <Markup content={brandDetails.ngo_contact_details} />
              </td>
            </tr>
            <tr className={classes.tr}>
              <td className={classes.tdHeader}>
                Inclusion criteria (服務對象)
              </td>
              <td className={classes.tdContent}>
                <Markup content={brandDetails.inclusion_criteria} />
              </td>
            </tr>
            <tr className={classes.tr}>
              <td className={classes.tdHeader}>
                Method of Application (Chinese)
              </td>
              <td className={classes.tdContent}>
                <Markup content={brandDetails.method_of_application} />
              </td>
            </tr>
            <tr className={classes.tr}>
              <td className={classes.tdHeader}>Specialty (please specify)</td>
              <td className={classes.tdContent}>
                {brandDetails.specialty.join(", ")}
              </td>
            </tr>
            <tr className={classes.tr}>
              <td className={classes.tdHeader}>Require receipt (Chinese)</td>
              <td className={classes.tdContent}>
                <Markup content={brandDetails.require_receipt} />
              </td>
            </tr>
            <tr className={classes.tr}>
              <td className={classes.tdHeader}>Branch Name</td>
              <td className={classes.tdContent}>
                {selectedContactInfo.branch_name}
              </td>
            </tr>
            <tr className={classes.tr}>
              <td className={classes.tdHeader}>Branch Address</td>
              <td className={classes.tdContent}>
                {selectedContactInfo.address_image_url && (
                  <img
                    src={selectedContactInfo.address_image_url}
                    alt="address map"
                  />
                )}
                <div>{selectedContactInfo.address}</div>
              </td>
            </tr>
            <tr className={classes.tr}>
              <td className={classes.tdHeader}>Branch E-mail</td>
              <td className={classes.tdContent}>{selectedContactInfo.email}</td>
            </tr>
            <tr className={classes.tr}>
              <td className={classes.tdHeader}>Branch Contact Number</td>
              <td className={classes.tdContent}>
                {selectedContactInfo.contact_number &&
                  selectedContactInfo.contact_number.map((number) => {
                    return number.label + " - " + number.value + " ";
                  })}
              </td>
            </tr>
            <tr className={classes.tr}>
              <th colSpan={2} className={classes.th}>
                Program Details
              </th>
            </tr>
            <tr className={classes.tr}>
              <td className={classes.tdHeader}>Patient Type</td>
              <td className={classes.tdContent}>
                <Markup content={selectedMechanics.patientType} />
              </td>
            </tr>
            <tr className={classes.tr}>
              <td className={classes.tdHeader}>Financial criteria</td>
              <td className={classes.tdContent}>
                <Markup content={selectedMechanics.incomeType} />
              </td>
            </tr>
            <tr className={classes.tr}>
              <td className={classes.tdHeader}>Program Details</td>
              <td className={classes.tdContent}>
                {selectedMechanics.mechanics.map((mechanic) => (
                  <Markup content={mechanic.label} />
                ))}
              </td>
            </tr>
          </table>
        </Box>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  programCatalogStore: state.programCatalogStore,
});

export default connect(mapStateToProps)(withStyles(styles)(PrintLayout));
