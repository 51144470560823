import React, { Component } from "react";
import { connect } from "react-redux";
import { IonPage, IonContent } from "@ionic/react";
import {
  Container,
  Grid,
  Box,
  createStyles,
  Typography,
  withStyles,
} from "@material-ui/core";

import Header from "../../common/Header";

// FORMS
import UserVerification from "../../components/Forms/UserVerification.form";

//IMG
import VerifyImg from "../../img/verification.png";

const styles = ({ spacing, palette }) =>
  createStyles({
    grid: {
      minHeight: "100vh",
    },
    verifyImg: {
      padding: spacing(8),
    },
    verifyText: {
      textAlign: "center",
    },
    formHeader: {
      textAlign: "center",
      marginBottom: spacing(4),
    },
    formBox: {
      width: "70%",
    },
  });

class VerificationPage extends Component {
  clickBackButton = () => {
    this.props.logoutUser();
  };

  render() {
    const { classes } = this.props;
    return (
      <IonPage>
        <Header
          title="Verification"
          backButton
          backButtonFunction={this.clickBackButton}
          searchButton={false}
        />
        <IonContent>
          <Container>
            <Grid container className={classes.grid}>
              <Grid container item xs={6} alignItems="center" justify="center">
                <img
                  src={VerifyImg}
                  className={classes.verifyImg}
                  alt="Mail Verification"
                />
              </Grid>
              <Grid container item xs={6} alignItems="center" justify="center">
                <Box className={classes.formBox}>
                  <Typography
                    variant="h5"
                    color="primary"
                    className={classes.formHeader}
                  >
                    Register with HKAPI
                  </Typography>
                  <Typography
                    variant="caption"
                    display="block"
                    className={classes.verifyText}
                  >
                    Please check your email for verification code.
                  </Typography>
                  <UserVerification
                    buttonSize="large"
                    history={this.props.history}
                  />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </IonContent>
      </IonPage>
    );
  }
}

const mapDispatchToProps = ({ authStore }) => ({
  ...authStore,
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(VerificationPage));
