import React from "react";
import { Formik, Form, Field } from "formik";
import { TextField, NumberField, SelectField } from "../FormFields";
import { Button } from "@material-ui/core";

const FormikForm = ({
  buttonClassName,
  buttonSize,
  initialValues = {},
  onSubmitHandler,
  formFields,
  validationSchema,
  customSubmitText,
  children,
}) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmitHandler}
      render={(props) => {
        return (
          <Form>
            {formFields &&
              formFields.map((field) => {
                let fieldType = {
                  type: "text",
                  component: TextField,
                };
                switch (field.type) {
                  case "text_input":
                  case "email_input":
                    fieldType = {
                      type: "text",
                      component: TextField,
                      upperCase:
                        field.field_type_options &&
                        field.field_type_options.should_uppercase,
                    };
                    break;
                  case "select_dropdown_input":
                    fieldType = {
                      component: SelectField,
                      options:
                        field.field_type_options &&
                        field.field_type_options.select_dropdown_options,
                    };
                    break;
                  case "secure_input":
                    fieldType = {
                      type: "password",
                      component: TextField,
                      upperCase:
                        field.field_type_options &&
                        field.field_type_options.should_uppercase,
                    };
                    break;
                  case "number_input":
                    fieldType = {
                      component: NumberField,
                    };
                    break;
                  default:
                    break;
                }
                return (
                  field.is_visible && (
                    <Field
                      key={field.name}
                      name={field.name}
                      {...fieldType}
                      placeholder={field.placeholder}
                      label={field.label || field.title || field.placeholder}
                      helperText={field.hint}
                      required={field.is_required}
                    />
                  )
                );
              })}

            {children}

            {(formFields || children) && (
              <Button
                className={buttonClassName}
                size={buttonSize}
                type="submit"
                disabled={!props.dirty || !props.isValid || props.isSubmitting}
                variant="contained"
                color="primary"
                style={{ marginTop: "20px" }}
                fullWidth
              >
                {customSubmitText || "SUBMIT"}
              </Button>
            )}
          </Form>
        );
      }}
    />
  );
};

export default FormikForm;
