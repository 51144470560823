import React from "react";
import { IonPage, IonContent } from "@ionic/react";
import { withMobileView } from "../../components/Mobile";
import Header from "../../common/Header";
import UserGuideContent from "./../../components/StaticContent/UserGuideContent";

const UserGuidePage = () => {
  return (
    <IonPage>
      <Header backButton={true} title="User Guide" light />
      <IonContent className="content">
        <UserGuideContent />
      </IonContent>
    </IonPage>
  );
};

export default withMobileView(UserGuidePage);
