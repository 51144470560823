import React from "react";
import { IonPage, IonContent } from "@ionic/react";
import { withMobileView } from "../../components/Mobile";
import Header from "../../common/Header";
import UserGuideContent from "./../../components/StaticContent/UserGuideContent";


const UserGuidePage = () => {
  return (
    <IonPage>
      <Header title="User Guide" />
      <IonContent className="content">
          <UserGuideContent/>
      </IonContent>
    </IonPage>
  );
};

export default withMobileView(UserGuidePage);
