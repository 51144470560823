import React from "react";
import { connect } from "react-redux";
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Divider,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import NGOIcon from "./../../img/icons/ngo.svg";
import DrugIcon from "./../../img/icons/drug.svg";
import { grey, blue } from "@material-ui/core/colors";
import Interweave from "interweave";

const styles = ({ spacing, palette }) =>
  createStyles({
    root: {
      marginTop: spacing(2),
      marginBottom: spacing(2),
      color: grey,
      minWidth: 280,
      cursor: "pointer",
    },
    cardContent: {
      height: "100%",
      minHeight: 200,
    },
    link: {
      textDecoration: "none",
    },
    icon: {
      marginRight: spacing(1),
      minWidth: 18,
    },
    brandName: {
      color: grey[900],
      lineHeight: 1,
      fontWeight: "bold",
    },
    genericName: {
      color: grey[600],
      marginBottom: spacing(2),
    },
    specialty: {
      color: blue[900],
      fontSize: 14,
      display: "flex",
    },
    ngo: {
      color: grey[800],
      "& p": { padding: 0, display: "inline" },
    },
    viewDetails: {
      textTransform: "none",
      color: "#ffffff",
    },
    cardActions: {
      backgroundColor: palette.primary.main,
    },
  });

const BrandDetail = ({
  brandId = "",
  brandName = "",
  genericName = "",
  specialty = [],
  ngo = "",
  classes = {},
  getBrandDetails,
}) => {
  const history = useHistory();
  return (
    <Card
      onClick={async () => {
        await getBrandDetails(brandId);
        history.push(`/details/${brandId}`);
      }}
      className={classes.root}
    >
      <CardContent className={classes.cardContent}>
        <Typography variant="h6" className={classes.brandName}>
          {brandName}
        </Typography>
        <Typography variant="body2" className={classes.genericName}>
          {genericName}
        </Typography>
        <div variant="body2" className={classes.specialty}>
          <div>
            <img src={DrugIcon} className={classes.icon} alt="specialty" />
          </div>

          <div>{specialty.join(", ")}</div>
        </div>
        {/* TODO: Does full text of NGO need to be displayed? */}
        <Typography variant="body2" className={classes.ngo} noWrap paragraph>
          <img
            src={NGOIcon}
            className={classes.icon}
            alt="Non-profit Organization"
          />
          <Interweave content={ngo} />
        </Typography>
      </CardContent>
      <Divider />
      <CardActions className={classes.cardActions}>
        <Button size="small" disableRipple className={classes.viewDetails}>
          View Details
        </Button>
      </CardActions>
    </Card>
  );
};

const mapDispatchToProps = ({ programCatalogStore }) => ({
  ...programCatalogStore,
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(BrandDetail));
