import * as Yup from "yup";

// TODO: move to db
const emailSuffixes = ['ha.org.hk', 'cuhk.edu.hk', 'hku.edu.hk', 'sjs.org.hk', 'hkacs.org.hk', 'yot.org.hk', 'hkarf.org', 'hk.skhlmc.org', 'pcfhk.org', 'hkapi.hk', 'hku.hk'];

export const generateValidationSchema = (formFields, customScheme = {}) => {
	let object = {};

	formFields &&
		formFields.forEach((field) => {
			let schema = Yup.string();

			if (field.is_required) {
				schema = schema.concat(
					Yup.string().required(field.error_message)
				);
			}

			if (field.regex_validations && field.regex_validations.length > 0) {
				field.regex_validations.forEach((validation) => {
					const regex = new RegExp(validation.regex);
					const regexSchema = Yup.string().matches(
						regex,
						validation.error_message
					);
					schema = schema.concat(regexSchema);
				});
			}

			if (field.name === "email") {
				schema = schema.concat(Yup.string().email().test('is-valid-email', `Email must come from one of the following domains: ${emailSuffixes.join(', ')}`, (value) => {
		        // const { path, createError } = this;
		        // [value] - value of the property being tested
		        // [path]  - property name,
		        // ...
		        if(value) {
			        const emailSuffix = value.split('@');

			        if(emailSuffix.length === 2) {
			        	return emailSuffixes.includes(emailSuffix[1]) || emailSuffix[1] === 'iqvia.com';
			        }
		        }
		        return false
		    }).required());
			}

			object[field.name] = schema;
		});

	return Yup.object().shape({
		...object,
		...customScheme,
	});
};
