import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Typography,
  Box,
  IconButton,
  createStyles,
  withStyles,
  Grid,
} from "@material-ui/core";
import {
  Bookmark as BookmarkIcon,
  BookmarkBorder as BookmarkBorderIcon,
  Print as PrintIcon,
} from "@material-ui/icons";
import { IonPage, IonContent } from "@ionic/react";

import { withMobileView } from "../../components/Mobile";
import Header from "../../common/Header";
import {
  ProgramInformation,
  ProgramMechanic,
  PrintLayout,
} from "../../components/Common";

import PrintFn from "../../services/print.service";

const styles = ({ spacing, palette }) =>
  createStyles({
    drugNameBox: {
      backgroundColor: "white",
    },
    drugNameContainer: {
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
      paddingTop: spacing(4),
      paddingBottom: spacing(4),
    },
    icon: {
      marginRight: spacing(1),
    },
    container: {
      paddingTop: spacing(2),
      paddingBottom: spacing(2),
    },
    isBookmarkedButton: {
      marginRight: spacing(2),
      color: palette.primary.dark,
      border: "2px solid " + palette.primary.dark,
    },
    notBookmarkedButton: {
      marginRight: spacing(2),
      color: palette.primary.dark,
      border: "2px solid " + palette.primary.dark,
    },
    printButton: {
      marginLeft: spacing(2),
    },
    brandTitle: {
      fontWeight: 600,
    },
  });

class DetailPage extends Component {
  constructor(props) {
    super(props);

    this.printLayout = React.createRef();
  }

  async componentDidMount() {
    const { brand_id } = this.props.match.params;
    await this.props.postTrackView({
      brand_id,
      user_id: this.props.userStore.userProfile.id,
    });
  }

  printFn = () => {
    PrintFn(this.printLayout.current);
  };

  addToBookmarks = async () => {
    const { brand_id } = this.props.match.params;
    await this.props.addToBookmarks(brand_id);
    await this.props.getBrandDetails(brand_id);
  };

  removeFromBookmarks = async () => {
    const { brand_id } = this.props.match.params;
    await this.props.removeFromBookmarks(brand_id);
    await this.props.getBrandDetails(brand_id);
  };

  render() {
    const { brandDetails } = this.props.programCatalogStore;
    const classes = this.props.classes;
    return (
      <React.Fragment>
        <PrintLayout />
        <IonPage className="no-print">
          <Header backButton={true} backToHome={false} light />
          <IonContent className="content">
            <Box className={classes.drugNameBox}>
              <Container className={classes.drugNameContainer}>
                <Grid container spacing={4}>
                  <Grid item sm={1}>
                    {brandDetails.bookmarked ? (
                      <IconButton
                        className={classes.isBookmarkedButton}
                        onClick={this.removeFromBookmarks}
                      >
                        <BookmarkIcon fontSize="large" />
                      </IconButton>
                    ) : (
                      <IconButton
                        className={classes.notBookmarkedButton}
                        onClick={this.addToBookmarks}
                      >
                        <BookmarkBorderIcon fontSize="large" />
                      </IconButton>
                    )}
                  </Grid>
                  <Grid item sm={10}>
                    <Typography className={classes.brandTitle} variant="h5">
                      {brandDetails.brand_name}
                    </Typography>
                    <Typography variant="body1">
                      {brandDetails.generic_name}
                    </Typography>
                  </Grid>
                  <Grid item sm={1} container justify="right">
                    <IconButton
                      className={classes.printButton}
                      onClick={this.printFn}
                    >
                      <PrintIcon fontSize="large" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Container>
            </Box>
            <Container className={classes.container}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <ProgramInformation brandDetails={brandDetails} />
                </Grid>
                <Grid item xs={6}>
                  <ProgramMechanic
                    scrollBottom={() => {}}
                    brandSupport={brandDetails.support}
                  />
                </Grid>
              </Grid>
            </Container>
          </IonContent>
        </IonPage>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  programCatalogStore: state.programCatalogStore,
  userStore: state.userStore,
});

const mapDispatchToProps = ({ programCatalogStore, userStore }) => ({
  ...programCatalogStore,
  ...userStore,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMobileView(withStyles(styles)(DetailPage)));
