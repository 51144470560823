import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Typography,
  Box,
  IconButton,
  createStyles,
  withStyles,
  Grid,
} from "@material-ui/core";
import {
  Bookmark as BookmarkIcon,
  BookmarkBorder as BookmarkBorderIcon,
  Print as PrintIcon,
} from "@material-ui/icons";
import { IonPage, IonContent } from "@ionic/react";

import { withMobileView } from "../../components/Mobile";
import Header from "../../common/Header";
import {
  ProgramInformation,
  ProgramMechanic,
  PrintLayout,
} from "../../components/Common";

import PrintFn from "../../services/print.service";

const styles = ({ spacing, palette }) =>
  createStyles({
    drugNameBox: {
      background: "linear-gradient(#014A90, #012169)",
      color: "white",
      paddingRight: spacing(2),
      paddingLeft: spacing(2),
      paddingTop: spacing(4),
      paddingBottom: spacing(4),
    },
    icon: {
      marginRight: spacing(1),
    },
    container: {
      marginTop: spacing(2),
      marginBottom: spacing(2),
    },
    isBookmarkedButton: {
      color: "white",
      border: "2px solid white",
    },
    notBookmarkedButton: {
      color: "white",
      border: "2px solid white",
    },
    printButton: {
      color: "white",
    },
    brandTitle: {
      fontWeight: 600,
    },
  });

class DetailPage extends Component {
  constructor(props) {
    super(props);
    this.printLayout = React.createRef();
  }

  printFn = () => {
    PrintFn(this.printLayout.current);
  };

  async componentDidMount() {
    const { brand_id } = this.props.match.params;
    await this.props.postTrackView({
      brand_id,
      user_id: this.props.userStore.userProfile.id,
    });
  }

  addToBookmarks = async () => {
    const { brand_id } = this.props.match.params;
    await this.props.addToBookmarks(brand_id);
    await this.props.getBrandDetails(brand_id);
  };

  removeFromBookmarks = async () => {
    const { brand_id } = this.props.match.params;
    await this.props.removeFromBookmarks(brand_id);
    await this.props.getBrandDetails(brand_id);
  };

  refForScrolling = null;
  scrollBottom = () => {
    this.refForScrolling.scrollToBottom();
  };

  render() {
    const { brandDetails } = this.props.programCatalogStore;
    const classes = this.props.classes;
    return (
      <React.Fragment>
        <PrintLayout ref={this.myRef} />
        <IonPage className="no-print">
          <Header backButton={true} backToHome={false} noShadow />
          <IonContent
            className="content"
            ref={(ref) => (this.refForScrolling = ref)}
          >
            <Box className={classes.drugNameBox}>
              <Grid item container spacing={0}>
                <Grid item xs={2}>
                  {brandDetails.bookmarked ? (
                    <IconButton
                      className={classes.isBookmarkedButton}
                      onClick={this.removeFromBookmarks}
                    >
                      <BookmarkIcon fontSize="medium" />
                    </IconButton>
                  ) : (
                    <IconButton
                      className={classes.notBookmarkedButton}
                      onClick={this.addToBookmarks}
                    >
                      <BookmarkBorderIcon fontSize="medium" />
                    </IconButton>
                  )}
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.brandTitle} variant="h5">
                    {brandDetails.brand_name}
                  </Typography>
                  <Typography variant="body1">
                    {brandDetails.generic_name}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    className={classes.printButton}
                    onClick={this.printFn}
                  >
                    <PrintIcon fontSize="large" />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
            <Container className={classes.container}>
              <ProgramInformation brandDetails={brandDetails} />
            </Container>
            <Container className={classes.container}>
              <ProgramMechanic
                scrollBottom={this.scrollBottom}
                brandSupport={brandDetails.support}
              />
            </Container>
          </IonContent>
        </IonPage>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  programCatalogStore: state.programCatalogStore,
  userStore: state.userStore,
});

const mapDispatchToProps = ({ programCatalogStore, userStore }) => ({
  ...programCatalogStore,
  ...userStore,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMobileView(withStyles(styles)(DetailPage)));
