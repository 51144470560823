import React from "react";
import {
  Typography,
  createStyles,
  withStyles,
  Paper,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from "@material-ui/core";

import SearchIcon from "@material-ui/icons/Search";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import FeaturedPlayListIcon from "@material-ui/icons/FeaturedPlayList";
import ListAltIcon from "@material-ui/icons/ListAlt";
import PrintIcon from "@material-ui/icons/Print";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import PersonIcon from "@material-ui/icons/Person";
import InfoIcon from "@material-ui/icons/Info";
import PhoneIcon from "@material-ui/icons/Phone";

import UserGuideVideo from "./UserGuideVideo";

const styles = ({ spacing, palette }) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      margin: spacing(2),
      padding: spacing(2),
    },
    header: {
      fontWeight: 700,
      marginBottom: spacing(2),
    },
    listItem: {
      fontSize: "0.9em",
    },
  });

const UserGuideContent = ({ classes = {} }) => {
  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={8}>
            <Paper className={classes.paper}>
              <Typography
                variant="h6"
                color="primary"
                className={classes.header}
              >
                User Guide Video
              </Typography>
              <UserGuideVideo />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper className={classes.paper}>
              <Typography variant="body2" paragraph>
                <b>“One platform”</b> is a centralized platform of Patient
                Support Programs (PSP) which aim to provide financial assistance
                to help patients get access to medicines. The platform provides
                updated information about the different PSPs that you can
                recommend to your patients.
              </Typography>
              <br />
              <Typography color="primary" paragraph>
                How to use:
              </Typography>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <SearchIcon color="primary" />
                  </ListItemAvatar>
                  <ListItemText
                    classes={{ primary: classes.listItem }}
                    primary={`
                      To search by Drug Name (generic or branded),Therapeutic
                      Area/NGO, simply go to the home page and type in the search bar
                    `}
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <FindInPageIcon color="primary" />
                  </ListItemAvatar>
                  <ListItemText
                    classes={{ primary: classes.listItem }}
                    primary={`
                      In the search results, click on the specific program that
                      you would like to view
                    `}
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <FeaturedPlayListIcon color="primary" />
                  </ListItemAvatar>
                  <ListItemText
                    classes={{ primary: classes.listItem }}
                    primary={`
                      You may view the program information as well as select the
                      Patient Type and choose the corresponding Financial Criteria
                    `}
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <ListAltIcon color="primary" />
                  </ListItemAvatar>
                  <ListItemText
                    classes={{ primary: classes.listItem }}
                    primary={`
                      It will show the corresponding program details that is for
                      the specific Patient Type and Financial Criteria
                    `}
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <PrintIcon color="primary" />
                  </ListItemAvatar>
                  <ListItemText
                    classes={{ primary: classes.listItem }}
                    primary={`
                      You may share the specific program details with your patient
                      by printing out the program
                    `}
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <BookmarkIcon color="primary" />
                  </ListItemAvatar>
                  <ListItemText
                    classes={{ primary: classes.listItem }}
                    primary={`
                      To bookmark the specific program, click on the Bookmark icon
                      on the top left of the screen
                    `}
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <PersonIcon color="primary" />
                  </ListItemAvatar>
                  <ListItemText
                    classes={{ primary: classes.listItem }}
                    primary={`
                      You may also change your profile details such as Area of
                      Specialisation, Workplace Name or change the Password by
                      going to the ‘Profile’ page
                    `}
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <InfoIcon color="primary" />
                  </ListItemAvatar>
                  <ListItemText
                    classes={{ primary: classes.listItem }}
                    primary={`
                      You may also wish to understand the mission of HKAPI by
                      going to the ‘About HKAPI’ page
                    `}
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <PhoneIcon color="primary" />
                  </ListItemAvatar>
                  <ListItemText
                    classes={{ primary: classes.listItem }}
                    primary={
                      <div>
                        For questions about the app, you may go to the ‘FAQ’
                        page or contact the hotline at{" "}
                        <Link
                          href="tel:+852 2528 3061"
                          className={classes.links}
                        >
                          +852 2528 3061
                        </Link>
                      </div>
                    }
                  />
                </ListItem>
              </List>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default withStyles(styles)(UserGuideContent);
