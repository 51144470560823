import _ from "lodash";

const { CONNECT_URLS, PROGRAM_ID } = window.config;
const userBaseUrl = `${CONNECT_URLS.users}/${PROGRAM_ID}`;
const programBaseUrl = `${CONNECT_URLS.programs}/${PROGRAM_ID}`;

export default {
	getUserProfile: async () => {
		return window.apiClient.get(`${userBaseUrl}/profile`);
	},
	getUserFields: async () => {
		const programConfig = await window.apiClient.get(
			`${programBaseUrl}/config`
		);
		const userType = _.find(programConfig.users.user_types, {
			user_type: window.config.USER_TYPE,
		});
		userType &&
			userType.user_field_configurations.forEach((field_config) => {
				if (
					field_config.field_type_options &&
					field_config.field_type_options.select_dropdown_options
				) {
					field_config.field_type_options.select_dropdown_options = _.orderBy(
						field_config.field_type_options.select_dropdown_options,
						["label"],
						["asc"]
					);
				}
			});
		return userType && userType.user_field_configurations;
	},
	postRegisterUser: async (payload) => {
		return window.apiClient.post(`${userBaseUrl}/registration`, payload);
	},
	postVerifyUser: async (payload) => {
		return window.apiClient.post(
			`${userBaseUrl}/registration/verify`,
			payload
		);
	},
	postResendVerification: async () => {
		return window.apiClient.post(
			`${userBaseUrl}/registration/verify/resend`
		);
	},
	putUpdateProfile: async (payload) => {
		return window.apiClient.put(`${userBaseUrl}/profile`, payload);
	},
	postUpdatePassword: async (payload) => {
		return window.apiClient.post(
			`${userBaseUrl}/profile/update-password`,
			payload
		);
	},
	postDeactivateAccount: async () => {
		return window.apiClient.post(`${userBaseUrl}/enrollment/deactivate`);
	},
};
