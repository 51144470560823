import React from "react";
import { connect } from "react-redux";
import { createStyles, withStyles } from "@material-ui/core";

import LogoColoredImg from "../../img/logo-colored.png";

const styles = ({ spacing, palette }) =>
	createStyles({
		loaderContainer: {
			width: "100%",
			height: "100vh",
			position: "relative",
			background: "#ffffff",
			zIndex: "999",
		},
		loaderContent: {
			position: "absolute",
			top: "50%",
			left: "50%",
			msTransform: "translateY(-50%) translateX(-50%)",
			transform: "translateY(-50%) translateX(-50%)",
			textAlign: "center",
		},
		loaderLogo: {
			width: "200px",
			marginBottom: "20px",
		},
	});

const Loader = (props) => (
	<div className={props.classes.loaderContainer}>
		<div className={props.classes.loaderContent}>
			<img
				src={LogoColoredImg}
				alt="HKAPI logo"
				className={props.classes.loaderLogo}
			/>
			<div>
				<b>Loading...</b>
			</div>
		</div>
	</div>
);

const mapStateToProps = (state) => ({
	uiStore: state.uiStore,
});

export default connect(mapStateToProps)(withStyles(styles)(Loader));
