import React, { Component } from "react";
import { connect } from "react-redux";
import { IonPage, IonContent } from "@ionic/react";
import {
  Typography,
  Divider,
  Container,
  withStyles,
  createStyles,
} from "@material-ui/core";

import { Link } from "react-router-dom";

import { withMobileView } from "../../components/Mobile";
import { BrandDetail } from "../../components/Common";
import SearchHeader from "../../components/Common/SearchHeader.component";
import { grey } from "@material-ui/core/colors";

import UserGuideModal from "./../../components/StaticContent/UserGuideModal";
import Header from "../../common/Header";
import SearchIcon from "@material-ui/icons/Search";

const styles = ({ spacing, palette }) =>
  createStyles({
    gridList: {
      flexWrap: "nowrap",
      display: "flex",
      overflow: "auto",
    },
    gridTile: {
      width: "280px",
      marginRight: spacing(2),
    },
    listHeader: {
      marginTop: spacing(2),
      color: grey[700],
    },
    searchContainer: {
      paddingBottom: spacing(2),
    },
    linkText: { textDecoration: "none" },
    link: { float: "right" },
    divider: {
      marginTop: spacing(3),
      marginBottom: spacing(4),
    },
    welcome: {
      color: grey[700],
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    welcomeText: {
      marginTop: 10,
      fontSize: 14,
      width: 400,
      textAlign: "center",
      lineHeight: 1.5,
    },
    welcomeIcon: {
      fontSize: 100,
    },
  });

const ListHeader = ({ heading = "", classes = null, link }) => {
  return (
    <Typography variant="body2" className={classes.listHeader}>
      {heading}
      <span className={classes.link}>
        <Link to={link} className={classes.linkText}>
          View All
        </Link>
      </span>
    </Typography>
  );
};

class ListPage extends Component {
  state = {
    userGuideModal: false,
  };

  async componentDidMount() {
    await this.props.refreshLocalStorage();
    await this.props.getProgramCatalog();
    await this.props.getRecentlyViewed();
    await this.props.getBookmarks();
    await this.props.getNGOList();
    await this.props.getTAList();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.isConnected !== prevProps.isConnected) {
      await this.props.refreshLocalStorage();
    }
  }

  toggle = () => this.setState({ dropdownOpen: !this.state.dropdownOpen });

  render() {
    const { bookmarks, recentlyViewed } = this.props.programCatalogStore;
    const { classes } = this.props;
    return (
      <IonPage>
        <Header title="List" responsiveHeader light />
        <IonContent className="content">
          <Container className={classes.searchContainer}>
            <SearchHeader
              onChange={(value) => this.setState({ searchText: value })}
            />
          </Container>
          <Divider className={classes.divider} />
          {recentlyViewed && recentlyViewed.length > 0 && (
            <React.Fragment>
              <Container>
                <ListHeader
                  heading="Recently Viewed"
                  classes={classes}
                  link="/recently-viewed"
                />
                <div className={classes.gridList}>
                  {recentlyViewed &&
                    recentlyViewed.slice(0, 3).map((brand) => (
                      <div className={classes.gridTile} key={brand.id}>
                        <BrandDetail
                          brandId={brand.id}
                          brandName={brand.brand_name}
                          genericName={brand.generic_name}
                          specialty={brand.specialty}
                          ngo={brand.ngo}
                        />
                      </div>
                    ))}
                </div>
              </Container>
              <Divider className={classes.divider} />
            </React.Fragment>
          )}
          {bookmarks && bookmarks.length > 0 && (
            <Container>
              <ListHeader
                heading="Bookmarked e-PSP"
                classes={classes}
                link="/bookmarks"
              />
              <div className={classes.gridList}>
                {bookmarks &&
                  bookmarks.length > 0 &&
                  bookmarks.slice(0, 3).map((brand) => (
                    <div className={classes.gridTile} key={brand.id}>
                      <BrandDetail
                        key={brand.id}
                        brandId={brand.id}
                        brandName={brand.brand_name}
                        genericName={brand.generic_name}
                        specialty={brand.specialty}
                        ngo={brand.ngo}
                      />
                    </div>
                  ))}
              </div>
            </Container>
          )}

          {bookmarks.length === 0 && recentlyViewed.length === 0 && (
            <Container>
              <div className={classes.welcome}>
                <SearchIcon className={classes.welcomeIcon} />
                <Typography variant="h6" className={classes.header}>
                  Welcome to HKAPI
                </Typography>
                <div className={classes.welcomeText}>
                  To get started with our one platform search, start searching
                  for programs available by filling the search form at the top
                </div>
              </div>
            </Container>
          )}

          <UserGuideModal
            locationString={this.props.location.search}
            history={this.props.history}
          />
        </IonContent>
      </IonPage>
    );
  }
}

const mapStateToProps = (state) => ({
  programCatalogStore: state.programCatalogStore,
});

const mapDispatchToProps = ({ programCatalogStore }) => ({
  ...programCatalogStore,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMobileView(withStyles(styles)(ListPage)));
