import React from "react";
import {
    Container,
    Typography,
    createStyles,
    withStyles,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Link
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = ({ spacing, palette, }) =>
  createStyles({
    root: {
        paddingTop: spacing(2),
    },
    header: {
      marginBottom: spacing(2),
      color: "grey"
    },
});


const FAQContent = ({ classes = {} }) => {
    return (
      <Container className={classes.root}>
        <Typography variant="h6" className={classes.header}>
          Frequently Asked Questions
        </Typography>
        <Typography variant="body2" paragraph>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                <b>
                  Who should I contact if I have enquiries about subscription of
                  HKAPI Patient Support Programs (PSPs) e-platform?
                </b>
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Please contact the HKAPI by phone <Link href="tel:+852 2528 3061" >+852 2528 3061</Link> or email <Link href="mailto: info@hkapi.hk" >info@hkapi.hk</Link>.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                <b>
                  Who should I contact if there is technical problem about
                  access or problem to the e-platform?
                </b>
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Please contact the HKAPI by phone <Link href="tel:+852 2528 3061" >+852 2528 3061</Link> or email <Link href="mailto: info@hkapi.hk" >info@hkapi.hk</Link>.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                <b>Will I be able to deactivate my account from the platform?</b>
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Yes, you can deactivate by clicking the “Deactivate Account button”
                located in the profile page
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                <b>
                  Who should I contact if the PSP information I need is not
                  available on the e-platform?
                </b>
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Please contact the organization who manages the PSP directly
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                <b>
                  Who should I contact if I have enquiries about the PSP
                  information offered by the e-platform?
                </b>
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Please contact the organization who manages the PSP directly
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Typography>
      </Container>
    );
}

export default withStyles(styles)(FAQContent);