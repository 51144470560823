import _ from "lodash";
import stores from "./index.model";
import generateErrorMessage from "../utilities/errorMessage.utility";
import { programCatalogService } from "../services/index.service";

const TA_LIST = window.config.TA_LIST;
const NGO_LIST = window.config.NGO_LIST;

export default {
  state: {
    isConnected: false,
    programCatalog: [],
    filteredProgramCatalog: [],
    ngoList: [...NGO_LIST],
    taList: [...TA_LIST],
    brandDetails: {
      brand_name: "",
      generic_name: "",
      ngo: "",
      ngo_contact_details: "",
      inclusion_criteria: "",
      method_of_application: "",
      specialty: [],
      require_receipt: "",
      contact_information: [
        {
          branch_name: "",
          address_image_url: "",
          address: "",
          email: "",
          contact_number: "",
        },
      ],
      created_at: "",
      updated_at: "",
      support: [],
      bookmarked: false,
    },
    recentlyViewed: [],
    bookmarks: [],
    selectedContactInfo: {
      branch_name: "",
      address_image_url: "",
      address: "",
      email: "",
      contact_number: "",
    },
    selectedMechanics: {
      patientType: "",
      incomeType: "",
      mechanics: [],
    },
    searchParams: {
      TA_selected: [],
      NGO_selected: [],
      searchText: "",
    },
  },

  reducers: {
    setProgramCatalog(state, payload) {
      return {
        ...state,
        programCatalog: payload,
      };
    },
    setFilteredProgramCatalog(state, payload) {
      return {
        ...state,
        filteredProgramCatalog: payload,
      };
    },
    setBrandDetails(state, payload) {
      return {
        ...state,
        brandDetails: payload,
      };
    },
    setRecentlyViewed(state, payload) {
      return {
        ...state,
        recentlyViewed: payload,
      };
    },
    setBookmarks(state, payload) {
      return {
        ...state,
        bookmarks: payload,
      };
    },
    setNGOList(state, payload) {
      return {
        ...state,
        ngoList: payload,
      };
    },
    setTAList(state, payload) {
      return {
        ...state,
        taList: payload,
      };
    },
    setIsConnected(state, payload) {
      return {
        ...state,
        isConnected: payload,
      };
    },
    setSelectedContactInfo(state, payload) {
      return {
        ...state,
        selectedContactInfo: payload,
      };
    },
    setSelectedMechanics(state, payload) {
      return {
        ...state,
        selectedMechanics: payload,
      };
    },
    setSearchParams(state, payload) {
      return {
        ...state,
        searchParams: payload,
      };
    },
  },

  effects: (dispatch) => ({
    refreshLocalStorage: async () => {
      try {
        await programCatalogService.refreshLocalStorage();
      } catch (err) {
        console.error(err);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: "warning",
        });
        throw err;
      }
    },

    getProgramCatalog: async () => {
      try {
        const { catalog } = await window.db.hkapi.get("brands");
        dispatch.programCatalogStore.setProgramCatalog(catalog);
        dispatch.programCatalogStore.setFilteredProgramCatalog(catalog);
      } catch (err) {
        console.error(err);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: "warning",
        });
        throw err;
      }
    },

    getFilteredProgramCatalog: async (payload) => {
      try {
        const catalog = await programCatalogService.getFilteredProgramCatalog(
          payload
        );

        dispatch.programCatalogStore.setFilteredProgramCatalog(catalog);
      } catch (err) {
        console.error(err);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: "warning",
        });
        throw err;
      }
    },

    getBrandDetails: async (brand_id) => {
      try {
        const { catalog: brands } = await window.db.hkapi.get("brands");

        const brandDetails = _.find(brands, { id: parseInt(brand_id) });
        dispatch.programCatalogStore.setBrandDetails({
          ...brandDetails,
          bookmarked: await checkIfBrandIsBookmarked(brand_id),
        });
        dispatch.programCatalogStore.setSelectedContactInfo(
          brandDetails?.contact_information[0] || []
        );
        dispatch.programCatalogStore.setSelectedMechanics({
          patientType: "",
          incomeType: "",
          mechanics: [],
        });

        // ADD to recently viewed
        await addToRecentlyViewed(brandDetails);
        dispatch.programCatalogStore.getRecentlyViewed();
      } catch (err) {
        console.error(err);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: "warning",
        });
        throw err;
      }
    },

    getRecentlyViewed: async () => {
      try {
        const { catalog: recently_viewed } = await window.db.hkapi.get(
          "recently_viewed"
        );
        const { catalog } = await window.db.hkapi.get("brands");
        let catalogGet = [];

        recently_viewed.forEach((item) => {
          for (var i = 0; i < catalog.length; i++) {
            if (item.id === catalog[i].id) {
              catalogGet.push(catalog[i]);
            }
          }
        });

        dispatch.programCatalogStore.setRecentlyViewed(catalogGet);
      } catch (err) {
        console.error(err);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: "warning",
        });
        throw err;
      }
    },

    postTrackView: async ({ brand_id, user_id }) => {
      try {
        await programCatalogService.postTrackView({ brand_id, user_id });
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    getBookmarks: async () => {
      try {
        const { catalog: bookmarks } = await window.db.hkapi.get("bookmarks");
        const { catalog } = await window.db.hkapi.get("brands");

        let catalogGet = [];

        bookmarks.forEach((item) => {
          for (var i = 0; i < catalog.length; i++) {
            if (item.id === catalog[i].id) {
              catalogGet.push(catalog[i]);
            }
          }
        });

        dispatch.programCatalogStore.setBookmarks(catalogGet);
      } catch (err) {
        console.error(err);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: "warning",
        });
        throw err;
      }
    },

    getNGOList: async () => {
      try {
        const email = await stores.getState().userStore.userProfile.email;
        let ngoList = [];

        const emailRestrictions = window.config.EMAIL_RESTRICTIONS;

        for (var i = 0; i < emailRestrictions.length; i++) {
          if (email.indexOf(emailRestrictions[i].suffix) >= 0) {
            ngoList = emailRestrictions[i].permitted_ngo;
            break;
          }
        }

        //Sort Alphabetical
        ngoList.sort((a, b) => b.localeCompare(a));

        dispatch.programCatalogStore.setNGOList(ngoList);
      } catch (err) {
        console.error(err);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: "warning",
        });
        throw err;
      }
    },

    getTAList: async () => {
      try {
        const { catalog } = await window.db.hkapi.get("brands");

        let taList = [];
        catalog.forEach((item) => {
          item.specialty.forEach((specialty) => {
            if (taList.indexOf(specialty) <= -1) {
              taList.push(specialty);
            }
          });
        });

        //Sort Alphabetically
        taList.sort((a, b) => a.localeCompare(b));

        dispatch.programCatalogStore.setTAList(taList);
      } catch (err) {
        console.error(err);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: "warning",
        });
        throw err;
      }
    },

    addToBookmarks: async (brand_id) => {
      try {
        const { catalog: brands } = await window.db.hkapi.get("brands");
        const brandDetails = _.find(brands, ["id", parseInt(brand_id, 10)]);
        await addBrandToBookmarks(brandDetails);
        dispatch.programCatalogStore.getBookmarks();
      } catch (err) {
        console.error(err);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: "warning",
        });
        throw err;
      }
    },

    removeFromBookmarks: async (brand_id) => {
      try {
        await removeBrandToBookmarks(brand_id);
        dispatch.programCatalogStore.getBookmarks();
      } catch (err) {
        console.error(err);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: "warning",
        });
        throw err;
      }
    },

    applySelectedContactInfo: async (payload) => {
      try {
        dispatch.programCatalogStore.setSelectedContactInfo(payload);
      } catch (err) {
        console.error(err);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: "warning",
        });
        throw err;
      }
    },

    applySelectedMechanics: async (payload) => {
      try {
        dispatch.programCatalogStore.setSelectedMechanics(payload);
      } catch (err) {
        console.error(err);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: "warning",
        });
        throw err;
      }
    },

    applySearchParams: async (payload) => {
      try {
        dispatch.programCatalogStore.setSearchParams(payload);
      } catch (err) {
        console.error(err);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: "warning",
        });
        throw err;
      }
    },
  }),
};

async function addToRecentlyViewed(brand) {
  // RETRIEVE recently viewed brands
  let recentlyViewed = await window.db.hkapi.get("recently_viewed");
  const limit = window.config.RECENTLY_VIEWED_LIMIT;

  // REMOVE current chosen brand details to prevent duplicates
  _.remove(recentlyViewed.catalog, { id: brand.id });

  // ARRANGE new recently viewed brands
  recentlyViewed.catalog = recentlyViewed.catalog.slice(0, limit - 1);
  recentlyViewed.catalog.splice(0, 0, { id: brand.id });

  // UPDATE recently viewed brands data
  await window.db.hkapi.put({
    _id: "recently_viewed",
    _rev: recentlyViewed._rev,
    catalog: recentlyViewed.catalog,
  });
  stores.dispatch.userStore.syncUserData();

  return recentlyViewed.catalog;
}

async function addBrandToBookmarks(brand) {
  // RETRIEVE bookmarks brands
  let bookmarks = await window.db.hkapi.get("bookmarks");
  // REMOVE current chosen brand details to prevent duplicates
  _.remove(bookmarks.catalog, { id: brand.id });

  // ARRANGE new bookmarks brands
  bookmarks.catalog.splice(0, 0, { id: brand.id });

  // UPDATE bookmarks brands data
  await window.db.hkapi.put({
    _id: "bookmarks",
    _rev: bookmarks._rev,
    catalog: bookmarks.catalog,
  });
  stores.dispatch.userStore.syncUserData();

  return bookmarks.catalog;
}

async function removeBrandToBookmarks(brand_id) {
  // RETRIEVE bookmarks brands
  let bookmarks = await window.db.hkapi.get("bookmarks");
  // REMOVE current chosen brand details
  _.remove(bookmarks.catalog, { id: parseInt(brand_id) });

  // UPDATE recently viewed brands data
  await window.db.hkapi.put({
    _id: "bookmarks",
    _rev: bookmarks._rev,
    catalog: bookmarks.catalog,
  });
  stores.dispatch.userStore.syncUserData();

  return bookmarks.catalog;
}

async function checkIfBrandIsBookmarked(brand_id) {
  // RETRIEVE bookmarks brands
  let bookmarks = await window.db.hkapi.get("bookmarks");
  return _.findIndex(bookmarks.catalog, { id: parseInt(brand_id) }) > -1;
}
