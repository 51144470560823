import React, { Component } from "react";
import { connect } from "react-redux";

import { withMobileView } from "./components/Mobile";
import { appService } from "./services/index.service";
import Loader from "./components/Loader/Loader.component";
import Toast from "./components/Toast/Toast.component";

import DesktopMainContainer from "./containers/desktop/MainContainer";
import MobileMainContainer from "./containers/mobile/MainContainer";

class MasterPage extends Component {
	async componentDidMount() {
		await this.props.checkUser();
    	await this.props.getUserFields();
		await appService.checkApplicationUpdate();

		if (this.props.authStore.isLoggedIn) {
			await this.props.getUserProfile();
		}
	}

	render() {
		const { isLoading } = this.props.uiStore;
		const { isMobileView } = this.props;

		return (
			<React.Fragment>
				{isMobileView ? (
									<MobileMainContainer />
								) : (
									<DesktopMainContainer />
								)}
				<Toast />
				{isLoading && <Loader />}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	uiStore: state.uiStore,
	authStore: state.authStore,
});

const mapDispatchToProps = ({ authStore, userStore }) => ({
	...authStore,
	...userStore,
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withMobileView(MasterPage));
