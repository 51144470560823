import React, { Component } from "react";
import { connect } from "react-redux";
import { IonRouterOutlet, IonSplitPane, IonMenu } from "@ionic/react";
import { Route, Redirect } from "react-router-dom";

import ListPage from "./ListPage";
import ViewPage from "./ViewPage";
import DetailPage from "./DetailPage";
import SearchPage from "./SearchPage";

import EndRedirect from "../../common/EndRedirect";
import LoginRedirect from "../../common/LoginRedirect";

import LoginPage from "./LoginPage";
import VerificationPage from "./VerificationPage";

import ProfilePage from "./ProfilePage";
import AboutPage from "./AboutPage";
import UserGuidePage from "./UserGuidePage";
import FAQPage from "./FAQPage";
import TermsAndConditionsPage from "./TermsAndConditionsPage";

import SidebarMenu from "../../components/Common/SidebarMenu.component";

class MainContainer extends Component {
  render() {
    const { isLoggedIn, loggedUser } = this.props.authStore;
    const { userProfile } = this.props.userStore;

    return isLoggedIn ? (
      loggedUser.email_verified || userProfile?.email_verified_at ? (
        <IonSplitPane contentId="main" when="xl">
          <IonMenu contentId="main">
            <SidebarMenu />
          </IonMenu>
          <IonRouterOutlet id="main">
          <Route path="/login" component={LoginPage} exact />
            <Route path="/list" component={ListPage} exact />
            <Route path="/bookmarks" component={ViewPage} exact />
            <Route path="/recently-viewed" component={ViewPage} exact />
            <Route path="/search" component={SearchPage} exact />
            <Route path="/details/:brand_id" component={DetailPage} exact />
            <Route path="/profile" component={ProfilePage} exact />
            <Route path="/about" component={AboutPage} exact />
            <Route path="/user-guide" component={UserGuidePage} exact />
            <Route
              path="/frequently-asked-questions"
              component={FAQPage}
              exact
            />
            <Route
              path="/terms-and-conditions"
              component={TermsAndConditionsPage}
              exact
            />

            <Route path="/" render={() => <Redirect to="/list" />} exact />
            <Redirect from="*" to="/list" />
          </IonRouterOutlet>
        </IonSplitPane>
      ) : (
        <React.Fragment>
          <Route path="/login" component={LoginPage} exact />
          <Route path="/verify" component={VerificationPage} exact />
          <Route path="/" render={() => <Redirect to="/verify" />} exact />
          <Redirect from="*" to="/verify" />
        </React.Fragment>
      )
    ) : (
      <IonRouterOutlet>
        <Route path="/end-redirect" component={EndRedirect} exact />
        <Route path="/login-redirect" component={LoginRedirect} exact />
        <Route path="/login" component={LoginPage} exact />
        <Route path="/verify" component={VerificationPage} exact />
        <Route
          path="/terms-and-conditions"
          component={TermsAndConditionsPage}
          exact
        />

        <Route path="/" render={() => <Redirect to="/login" />} exact />
        <Redirect from="*" to="/login" />
      </IonRouterOutlet>
    );
  }
}

const mapStateToProps = (state) => ({
  authStore: state.authStore,
  userStore: state.userStore,
});

export default connect(mapStateToProps)(MainContainer);
