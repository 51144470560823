import { programService } from '../services/index.service';
import stores from "./index.model";
import generateErrorMessage from "../utilities/errorMessage.utility";

export default {
  state: {
    programConfig: {
      program_id: "",
      program_name: "",
      program_code: "",
      program_type: "",
      default_campaign_id: "",
      countries: [],
      languages: [],
      allowed_program_users_count: 0,
      start_date: new Date(),
      keycloak_connect_configuration: {},
      end_date: new Date(),
      program_guide_link: "",
      product_selection_type: "",
      created_at: new Date(),
      updated_at: new Date(),
      deleted_at: null,
      users: {
        is_enabled: true,
        user_types: [],
      },
    },
  },

  reducers: {
    setProgramConfig(state, payload) {
      return {
        ...state,
        programConfig: payload,
      };
    },
  },

  effects: (dispatch) => ({
    getProgramConfig: async () => {
      try {
        const programConfigResult = await programService.getProgramConfig();

        dispatch.programStore.setProgramConfig(programConfigResult);
        return
      } catch (err) {
        console.error(err);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: "warning",
        });
        throw err;
      }
    },
  }),
};
