import React, { Component } from "react";
import { connect } from "react-redux";
import { Field } from "formik";
import * as Yup from "yup";

import FormikForm from "../../components/FormikForm/FormikForm.component";
import { TextField } from "../../components/FormFields";

class UpdatePassword extends Component {
  handleUpdatePassword = async (values, actions) => {
    actions.setSubmitting(true);

    try {
      const payload = values;
      const submitAction = this.props.putUpdatePassword;

      await submitAction(payload);
      actions.resetForm();
    } catch (err) {
      console.log(err);
    } finally {
      actions.setSubmitting(false);
    }
  };

  render() {
    return (
      <FormikForm
        onSubmitHandler={this.handleUpdatePassword}
        validationSchema={Yup.object().shape({
          old_password: Yup.string()
            .matches(/^.{8,}$/, "Your old password needs to be at least 8 characters long.")
            .matches(/.*[A-Z]/, "Your old password needs to include at least one upper case English Letter.")
            .matches(/.*[a-z]/, "Your old password needs to include at least one lower case English Letter.")
            .matches(/^.*[0-9]/, "Your old password needs to include at least one number.")
            .matches(/^.*[@#$%^&+=!*()?]/, "Your old password needs to include at least one special character.")
            .required("Old Password is required"),
          password: Yup.string()
            .notOneOf([Yup.ref('old_password')], 'Your new password should not match your old password.')
            .matches(/^.{8,}$/, "Your new password needs to be at least 8 characters long.")
            .matches(/.*[A-Z]/, "Your new password needs to include at least one upper case English Letter.")
            .matches(/.*[a-z]/, "Your new password needs to include at least one lower case English Letter.")
            .matches(/^.*[0-9]/, "Your new password needs to include at least one number.")
            .matches(/^.*[@#$%^&+=!*()?]/, "Your new password needs to include at least one special character.")
            .required("New Password is required"),
          confirm_password: Yup.string()
            .notOneOf([Yup.ref('old_password')], 'Confirm password should not match your old password.')
            .oneOf([Yup.ref('password')], 'Confirm password should match your new password.')
            .matches(/^.{8,}$/, "Confirm password needs to be at least 8 characters long.")
            .matches(/.*[A-Z]/, "Confirm password needs to include at least one upper case English Letter.")
            .matches(/.*[a-z]/, "Confirm password needs to include at least one lower case English Letter.")
            .matches(/^.*[0-9]/, "Confirm password needs to include at least one number.")
            .matches(/^.*[@#$%^&+=!*()?]/, "Confirm password needs to include at least one special character.")
            .required("Confirm Password is required"),
        })}
        customSubmitText="Update Password"
      >
        <Field
          name="old_password"
          component={TextField}
          type="password"
          placeholder="Old Password"
          required
        />
        <Field
          name="password"
          component={TextField}
          type="password"
          placeholder="New Password"
          required
        />
        <Field
          name="confirm_password"
          component={TextField}
          type="password"
          placeholder="Confirm Password"
          required
        />
      </FormikForm>
    );
  }
}

const mapDispatchToProps = ({ userStore }) => ({
  ...userStore,
});

export default connect(null, mapDispatchToProps)(UpdatePassword);
