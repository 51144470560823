import React, { Component } from "react";
import NumberFormat from "react-number-format";

import { Grid, TextField as TextInput, Typography } from "@material-ui/core";
import { ErrorMessage } from "formik";

class Input extends Component {
  render() {
    return <TextInput {...this.props} type="text" />;
  }
}

class NumberField extends Component {
  handleChange = (values) => {
    this.props.form.setFieldValue(this.props.field.name, values.floatValue);
  };

  render() {
    const {
      props: {
        field,
        label,
        placeholder,
        commaSeparator = false,
        decimalScale = 0,
        helperText,
        required,
      },
      handleChange,
    } = this;

    return (
      <Grid container spacing={4} key={field.name}>
        <Grid item sm={12} xs={12}>
          <NumberFormat
            id={this.props.field.name}
            name={this.props.field.name}
            type="text"
            label={label ? `${label} ${required && "*"}` : placeholder}
            allowNegative={false}
            allowLeadingZeros={false}
            customInput={Input}
            onValueChange={handleChange}
            onBlur={field.onBlur}
            thousandSeparator={commaSeparator}
            decimalScale={decimalScale}
            value={this.props.field.value || ""}
            helperText={helperText}
            fullWidth
          />

          <ErrorMessage
            name={field.name}
            render={(message) => {
              return (
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  color="error"
                >
                  {message}
                </Typography>
              );
            }}
          />
        </Grid>
      </Grid>
    );
  }
}

export default NumberField;
