import React, { Component } from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { ErrorMessage } from "formik";

class SelectField extends Component {
  state = {
    selectedOthersOption: false,
  };

  handleChange = (event, newValue) => {
    this.props.form.setFieldValue(
      this.props.field.name,
      newValue && newValue.value
    );
    if (newValue && newValue.value.toLowerCase() !== "others") {
      this.setState({ selectedOthersOption: false });
    } else {
      this.setState({ selectedOthersOption: true });
    }
  };

  handleSpecificText = (e) => {
    this.props.form.setFieldValue(
      `${this.props.field.name}_specific`,
      e.target.value
    );
  };

  render() {
    const { selectedOthersOption } = this.state;
    const {
      field,
      label,
      options = [],
      disabled,
      helperText,
      required,
    } = this.props;

    return (
      <Grid container spacing={4} key={field.name}>
        <Grid item sm={12} xs={12}>
          <Autocomplete
            value={
              selectedOthersOption
                ? { label: "Others", value: "Others" }
                : field.value
                ? { label: field.value, value: field.value }
                : null
            }
            options={options}
            getOptionLabel={(option) => option && option.label}
            onChange={this.handleChange}
            disableClearable
            disablePortal
            disabled={disabled}
            renderInput={(params) => (
              <TextField
                {...params}
                label={`${label}${required ? " *" : ""}`}
                name={selectedOthersOption ? "select-field" : field.name}
                onBlur={field.onBlur}
              />
            )}
          />

          {selectedOthersOption && (
            <TextField
              type="text"
              label={`Enter ${label}`}
              // value={field.value || ""}
              onChange={this.handleSpecificText}
              // onBlur={field.onBlur}
              // name={field.name}
              disabled={disabled}
              helperText={helperText}
              fullWidth
            />
          )}

          {helperText && (
            <Typography variant="caption" display="block" gutterBottom>
              {helperText}
            </Typography>
          )}

          <ErrorMessage
            name={field.name}
            render={(message) => {
              return (
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  color="error"
                >
                  {message}
                </Typography>
              );
            }}
          />
        </Grid>
      </Grid>
    );
  }
}

export default SelectField;
