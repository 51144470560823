import { Plugins } from "@capacitor/core";
import PouchDB from "pouchdb";
import axios from "axios";
import _ from "lodash";

import stores from "../models/index.model";

const { Network, Modals } = Plugins;

export default {
  intializeDatabase: async () => {
    await initializeData();
    stores.dispatch.uiStore.showLoading(false);
  },
  refreshLocalStorage: async () => {
    // CHECK connection status
    console.log("Refresh Local Storage");
    const networkStatus = await Network.getStatus();
    await initializeData();
    await stores.dispatch.userStore.getUserData();
    await updateData(networkStatus.connected);
  },
  setConnectionStatus: async (networkStatus) => {
    console.log("connection status: ", networkStatus);
    stores.dispatch.programCatalogStore.setIsConnected(networkStatus.connected);
  },
  getFilteredProgramCatalog: async (payload) => {
    let { searchText = "", TA_selected = [], NGO_selected = [] } = payload;
    let { catalog } = await window.db.hkapi.get("brands");

    searchText = searchText.replace(/[.,\/#!$%\^&\*;"':{}=\-_`~()]/g, "");

    const brandRelevance = [
      { property: "brand_name", score: 1 },
      { property: "generic_name", score: 2 },
      { property: "ngo", score: 6 },
      { property: "ngo_contact_details", score: 6 },
      { property: "inclusion_criteria", score: 5 },
      { property: "method_of_application", score: 5 },
      { property: "require_receipt", score: 5 },
      { property: "created_at", score: 8 },
      { property: "updated_at", score: 8 },
      { property: "contact_information", score: 7 },
      { property: "specialty", score: 3 },
      { property: "Patient type", score: 4 },
      { property: "Financial criteria", score: 6 },
      { property: "Program details", score: 5 },
    ];

    catalog = _.filter(catalog, (o) => {
      //If not part of ngo list don't include

      let ngo_found = true;
      let ta_found = true;

      if (NGO_selected.length > 0 || TA_selected.length > 0) {
        ngo_found = false;
        for (var i = 0; i < NGO_selected.length; i++) {
          if (o.ngo.indexOf(NGO_selected[i]) >= 0) {
            ngo_found = true;
          }
        }

        //If not part of ta list don't include

        let taFound = false;
        o.specialty.forEach((specialty) => {
          if (TA_selected.includes(specialty)) {
            taFound = true;
          }
        });

        if (!taFound) {
          ta_found = false;
        }
      }

      if (!ngo_found && !ta_found) return false;

      //Check each property of brand and check if searchText is found. Replace score for sorting if its lower.
      let searchTextFound = false;
      o.score = 99;
      brandRelevance.forEach((brandScore) => {
        let foundInProperty = false;

        //Check Objects First if it has search text

        if (brandScore.property === "specialty") {
          if (
            JSON.stringify(o.specialty)
              .toLowerCase()
              .replace(/[.,\/#!$%\^&\*;"':{}=\-_`~()]/g, "")
              .indexOf(searchText.toLowerCase()) !== -1
          ) {
            foundInProperty = true;
          }
        } else if (brandScore.property === "contact_information") {
          const contactFields = [
            "address",
            "branch_name",
            "contact_number",
            "email",
          ];

          o.contact_information.forEach((contact) => {
            contactFields.forEach((field) => {
              if (field === "contact_number") {
              } else if (
                contact[field] &&
                contact[field]
                  .toLowerCase()
                  .replace(/[.,\/#!$%\^&\*;"':{}=\-_`~()]/g, "")
                  .indexOf(searchText.toLowerCase()) !== -1
              ) {
                foundInProperty = true;
              }
            });
          });
        } else if (brandScore.property === "Patient type") {
          o.support.forEach((patientType) => {
            if (
              patientType.label
                .toLowerCase()
                .replace(/[.,\/#!$%\^&\*;"':{}=\-_`~()]/g, "")
                .indexOf(searchText.toLowerCase()) !== -1
            ) {
              foundInProperty = true;
            }
          });
        } else if (brandScore.property === "Financial criteria") {
          o.support.forEach((patientType) => {
            patientType.children.forEach((incomeType) => {
              if (
                incomeType.label
                  .toLowerCase()
                  .replace(/[.,\/#!$%\^&\*;"':{}=\-_`~()]/g, "")
                  .indexOf(searchText.toLowerCase()) !== -1
              ) {
                foundInProperty = true;
              }
            });
          });
        } else if (brandScore.property === "Program details") {
          o.support.forEach((patientType) => {
            patientType.children.forEach((incomeType) => {
              incomeType.children.forEach((detail) => {
                if (
                  detail.label
                    .toLowerCase()
                    .replace(/[.,\/#!$%\^&\*;"':{}=\-_`~()]/g, "")
                    .indexOf(searchText.toLowerCase()) !== -1
                ) {
                  foundInProperty = true;
                }
              });
            });
          });
        }
        //Check the rest of the strings if it has search text
        else if (
          o[brandScore.property] &&
          o[brandScore.property]
            .toLowerCase()
            .replace(/[.,\/#!$%\^&\*;"':{}=\-_`~()]/g, "")
            .indexOf(searchText.toLowerCase()) !== -1
        ) {
          foundInProperty = true;
        }

        //if found attach score
        if (foundInProperty) {
          searchTextFound = true;
          o.score = brandScore.score < o.score ? brandScore.score : o.score;
        }
      });

      return searchTextFound;
    });

    const email = await stores.getState().userStore.userProfile.email;
    if (window.config.CUSTOM_EMAIL_RESTRICTIONS.includes(email)) {
      let emailPrefix = email.split("_")[0];
      if (emailPrefix === "az") {
        emailPrefix = "AstraZeneca"
      } else if (emailPrefix === "bi") {
        emailPrefix = "Boehringer Ingelheim"
      }
      catalog = _.filter(catalog, (a) => {
        return emailPrefix.toLowerCase().includes(a.drug_manufacturer.toLowerCase())
      })
    }

    //Sort Alphabetical First
    catalog.sort((a, b) => a.brand_name.localeCompare(b.brand_name));

    //Sort by Score
    catalog.sort((a, b) => {
      return a.score - b.score;
    });
    return catalog;
  },
  postTrackView: async ({ brand_id, user_id }) => {
    return (
      await axios.post(`${window.config.API_URL}/track-view`, {
        brand_id,
        user_id,
      })
    ).data;
  },
  destroyDatabase: async () => {
    console.log("Destroy Database");
    try {
      await window.db.hkapi.destroy();
    } catch (e) {
      console.error(e);
    }
  },
};

async function initializeData() {
  console.log("Initialize Database");
  try {
    // VALIDATE if version is empty
    const version = await window.db.hkapi.allDocs();
    console.log(version);
    if (version.total_rows < 4) {
      window.db.hkapi = new PouchDB("hkapi");

      // RETRIEVE initial data from json file
      const {
        version: initialVersion,
        programCatalog: initialProgramCatalog,
      } = require("../initialData.json");

      // POPULATE version
      await window.db.hkapi.put({
        _id: "app_version",
        ...initialVersion,
      });
      console.log("Initialized version data.");

      // POPULATE program catalog
      await window.db.hkapi.put({
        _id: "brands",
        //catalog: [initialProgramCatalog],
        catalog: [],
      });
      console.log("Initialized program catalog data.");

      // CREATE initial recently viewed data
      await window.db.hkapi.put({
        _id: "recently_viewed",
        catalog: [],
      });
      console.log("Initialized recently viewed data.");

      // CREATE initial Bookmarks data
      await window.db.hkapi.put({
        _id: "bookmarks",
        catalog: [],
      });
      console.log("Initialized Bookmarks data.");

      //stores.dispatch.uiStore.showLoading(false);

      return;
    }
  } catch (err) {
    console.log(err.message);
    await Modals.alert({
      title: "Initializing Data",
      message: err.message,
    });
    window.location.reload();
    throw err;
  }
}

async function updateData(networkConnected) {
  try {
    if (!networkConnected) {
      stores.dispatch.uiStore.showToast({
        message: "You are currently offline.",
        color: "warning",
      });
      return;
    }

    // RETRIEVE current version
    const currentVersion = await window.db.hkapi.get("app_version");

    // RETRIEVE latest version
    let { result: latestVersion } = (
      await axios.get(`${window.config.API_URL}/version`, {
        timeout: 5000,
      })
    ).data;

    await stores.dispatch.userStore.getUserProfile();
    const email = await stores.getState().userStore.userProfile.email;

    if (
      latestVersion &&
      currentVersion.hash !== latestVersion.hash &&
      email !== ""
    ) {
      console.log("Data Mismatch");
      //stores.dispatch.uiStore.showLoading(true);

      console.log(latestVersion);
      // CREATE version
      await window.db.hkapi.put({
        _id: "app_version",
        _rev: currentVersion._rev,
        ...latestVersion,
      });
      console.log("Updated version data.");

      // RETRIEVE latest program catalog
      let { result: latestProgramCatalog } = (
        await axios.get(`${window.config.API_URL}/program-catalog`)
      ).data;

      // CREATE program catalog / brands

      const currentBrandCatalog = await window.db.hkapi.get("brands");

      console.log(latestProgramCatalog.length);

      latestProgramCatalog = await filterProgramCatalogViaEmail(
        email,
        latestProgramCatalog
      );
      await window.db.hkapi.put({
        _id: "brands",
        _rev: currentBrandCatalog._rev,
        catalog: latestProgramCatalog,
      });
      console.log("Updated program catalog data.");

      // REMOVE Programs not in the program catalog list in recently viewed and booked marked
      await removePrograms(latestProgramCatalog);

      //stores.dispatch.uiStore.showLoading(false);

      stores.dispatch.uiStore.showToast({
        message: "Data has been successfully updated.",
        color: "success",
      });
    }

    return;
  } catch (err) {
    console.log(err.message);
    stores.dispatch.uiStore.showLoading(false);
    stores.dispatch.uiStore.showToast({
      message: err.message,
      color: "warning",
    });
    throw err;
  }
}

async function removePrograms(latestProgramCatalog) {
  const recentlyViewedGet = await window.db.hkapi.get("recently_viewed");
  const bookedMarkedGet = await window.db.hkapi.get("bookmarks");

  let recentlyViewed = recentlyViewedGet.catalog;
  let bookedMarked = bookedMarkedGet.catalog;

  //Get missing programs
  let missingRecentlyViewed = [];

  for (let i = 0; i < recentlyViewed.length; i++) {
    let found = false;

    for (let j = 0; j < latestProgramCatalog.length; j++) {
      const programGet = latestProgramCatalog[j];

      if (programGet.id === recentlyViewed[i].id) {
        found = true;
        break;
      }
    }
    if (!found) {
      missingRecentlyViewed.push(recentlyViewed[i]);
    }
  }

  let missingBookedMarked = [];

  for (let i = 0; i < bookedMarked.length; i++) {
    let found = false;

    for (let j = 0; j < latestProgramCatalog.length; j++) {
      const programGet = latestProgramCatalog[j];
      if (programGet.id === bookedMarked[i].id) {
        found = true;
        break;
      }
    }
    if (!found) {
      missingBookedMarked.push(bookedMarked[i]);
    }
  }

  //Remove missing programs
  missingRecentlyViewed.forEach(function (brand) {
    var itemIndex = recentlyViewed.findIndex((i) => i.id === brand.id);
    recentlyViewed.splice(itemIndex, 1);
  });

  missingBookedMarked.forEach(function (brand) {
    var itemIndex = bookedMarked.findIndex((i) => i.id === brand.id);
    bookedMarked.splice(itemIndex, 1);
  });

  //Update Pouch db
  await window.db.hkapi.put({
    _id: "recently_viewed",
    _rev: recentlyViewedGet._rev,
    catalog: recentlyViewed,
  });

  await window.db.hkapi.put({
    _id: "bookmarks",
    _rev: bookedMarkedGet._rev,
    catalog: bookedMarked,
  });

  await stores.dispatch.userStore.syncUserData();
}

async function filterProgramCatalogViaEmail(email, catalog) {
  let NGO_selected = [];
  let permit_all_ngo = false;
  console.log(email);

  const emailRestrictions = window.config.EMAIL_RESTRICTIONS;

  for (var i = 0; i < emailRestrictions.length; i++) {
    if (email.indexOf(emailRestrictions[i].suffix) >= 0) {
      NGO_selected = emailRestrictions[i].permitted_ngo;
      permit_all_ngo = emailRestrictions[i].permit_all_ngo;
      break;
    }
  }

  //Filter if not permit_all_ngo
  if (!permit_all_ngo) {
    catalog = _.filter(catalog, (o) => {
      let ngo_found = false;
      for (var i = 0; i < NGO_selected.length; i++) {
        if (o.ngo && o.ngo.indexOf(NGO_selected[i]) >= 0) {
          ngo_found = true;
        }
      }
      return ngo_found;
    });
  }
  console.log(catalog);
  return catalog;
}
