/* eslint-disable */
require('dotenv').config();

import React from "react";
import ReactDOM from "react-dom";
import PouchDB from "pouchdb";
import { Plugins } from "@capacitor/core";
import { Deeplinks } from "@ionic-native/deeplinks";
import { ScreenOrientation } from "@ionic-native/screen-orientation";

import MainPage from "./MainPage";
import * as serviceWorker from "./serviceWorker";
import "./config";
import { appService, programCatalogService } from "./services/index.service";

// SCSS
import "./scss/ionic-overrides.scss";
/* eslint-enable */

const { Network, App } = Plugins;

ScreenOrientation.lock("portrait");

Network.addListener("networkStatusChange", async (status) => {
  console.log("Network State Changed");
  await programCatalogService.setConnectionStatus(status.connected);
});

App.addListener("appStateChange", async (state) => {
  if (state.isActive) {
    console.log("App State Changed");
    await appService.checkApplicationUpdate();
    subscribeDeeplinks();
  }
});

const subscribeDeeplinks = () => {
  Deeplinks.route({
    "/signin-callback.html": null,
    "/login": null,
  }).subscribe(
    (match) => {
      // match.$route - the route we matched, which is the matched entry from the arguments to route()
      // match.$args - the args passed in the link
      // match.$link - the full link data
      if (["/end-redirect", "/login-redirect"].includes(match.$link.host)) {
        window.location.replace(
          `.${match.$link.host}${
            match.$link.queryString && "?" + match.$link.queryString
          }`
        );
      }
    },
    (nomatch) => {
      // nomatch.$link - the full link data
      console.error("Got a deeplink that didn't match", nomatch);
    }
  );
};

subscribeDeeplinks();

window.db = {
  hkapi: new PouchDB("hkapi"),
};
window.apiClient = require("./utilities/apiClient.utility").default;

//programCatalogService.intializeDatabase();

ReactDOM.render(<MainPage />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
