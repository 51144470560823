window.config = {
  API_URL: process.env.REACT_APP_API_URL,
  KEYCLOAK_CONFIG: JSON.parse(process.env.REACT_APP_KEYCLOAK_CONFIG),
  CONNECT_URLS: JSON.parse(process.env.REACT_APP_CONNECT_URLS),
  PROGRAM_ID: JSON.parse(process.env.REACT_APP_KEYCLOAK_CONFIG).realm,
  USER_TYPE: "hkapi-user",
  RECENTLY_VIEWED_LIMIT: 10,
  APP_ID: null,
  TA_LIST: [
    "Anaesthesiology",
    "Intensive Care",
    "Pain Medicine",
    "Community Medicine",
    "Emergency Medicine",
    "Family Medicine",
    "Obstetrics & Gynaecology",
    "Gynaecological Oncology",
    "Maternal & Fetal Medicine",
    "Reproductive Medicine",
    "Urogynaecology",
    "Ophthalmology",
    "Orthopaedics & Traumatology",
    "Otorhinolaryngology",
    "Paediatrics",
    "Developmental-Behavioural Paediatrics",
    "Genetics and Genomics (Paediatrics)",
    "Paediatric Endocrinology",
    "Paediatric Immunology & Infectious Diseases",
    "Paediatric Neurology",
    "Paediatric Respiratory Medicine",
    "Pathology",
    "Anatomical Pathology",
    "Chemical Pathology",
    "Forensic Pathology",
    "Genetic and Genomic Pathology",
    "Haematology",
    "Immunology",
    "Clinical Microbiology and Infection",
    "Cardiology",
    "Clinical Pharmacology and Therapeutics",
    "Critical Care Medicine",
    "Dermatology & Venereology",
    "Endocrinology, Diabetes & Metabolism",
    "Gastroenterology and Hepatology",
    "Geriatric Medicine",
    "Haematology & Haematological Oncology",
    "Immunology & Allergy",
    "Infectious Disease",
    "Internal Medicine",
    "Medical Oncology",
    "Nephrology",
    "Neurology",
    "Palliative Medicine",
    "Respiratory Medicine",
    "Rheumatology",
    "Psychiatry",
    "Clinical Oncology",
    "Nuclear Medicine",
    "Radiology",
    "Cardio-thoracic Surgery",
    "General Surgery",
    "Neurosurgery",
    "Paediatric Surgery",
    "Plastic Surgery",
    "Urology",
  ],
  NGO_LIST: [
    "聖雅各福群會惠澤社區藥房",
    "仁愛堂鄭丁港善心藥物中心",
    "香港聖公會麥理浩夫人中心慈惠社區藥房",
    "香港藥學服務基金配安心藥房",
    "香港防癌會",
  ],
  CUSTOM_EMAIL_RESTRICTIONS: [
    "abbvie_oneplatform@hkapi.hk",
    "amgen_oneplatform@hkapi.hk",
    "az_oneplatform@hkapi.hk",
    "bi_oneplatform@hkapi.hk",
    "bms_oneplatform@hkapi.hk",
    "eisai_oneplatform@hkapi.hk",
    "janssen_oneplatform@hkapi.hk",
    "novartis_oneplatform@hkapi.hk",
    "roche_oneplatform@hkapi.hk",
    "takeda_oneplatform@hkapi.hk",
  ],
  EMAIL_RESTRICTIONS: [
    { suffix: "abbvie_oneplatform@hkapi.hk", permitted_ngo: [] },
    {
      suffix: "amgen_oneplatform@hkapi.hk",
      permitted_ngo: ["聖雅各福群會惠澤社區藥房"],
    },
    {
      suffix: "az_oneplatform@hkapi.hk",
      permitted_ngo: ["聖雅各福群會惠澤社區藥房"],
    },
    {
      suffix: "bi_oneplatform@hkapi.hk",
      permitted_ngo: [
        "香港藥學服務基金配安心藥房",
        "香港聖公會麥理浩夫人中心慈惠社區藥房",
        "仁愛堂鄭丁港善心藥物中心",
        "聖雅各福群會惠澤社區藥房",
      ],
    },
    {
      suffix: "bms_oneplatform@hkapi.hk",
      permitted_ngo: ["聖雅各福群會惠澤社區藥房", "香港防癌會"],
    },
    {
      suffix: "eisai_oneplatform@hkapi.hk",
      permitted_ngo: ["聖雅各福群會惠澤社區藥房", "香港藥學服務基金配安心藥房"],
    },
    {
      suffix: "janssen_oneplatform@hkapi.hk",
      permitted_ngo: ["聖雅各福群會惠澤社區藥房", "香港防癌會"],
    },
    {
      suffix: "novartis_oneplatform@hkapi.hk",
      permitted_ngo: [
        "聖雅各福群會惠澤社區藥房",
        "仁愛堂鄭丁港善心藥物中心",
        "香港聖公會麥理浩夫人中心慈惠社區藥房",
        "香港藥學服務基金配安心藥房",
      ],
    },
    {
      suffix: "roche_oneplatform@hkapi.hk",
      permitted_ngo: ["聖雅各福群會惠澤社區藥房", "香港防癌會"],
    },
    {
      suffix: "takeda_oneplatform@hkapi.hk",
      permitted_ngo: ["聖雅各福群會惠澤社區藥房", "香港藥學服務基金配安心藥房"],
    },
    { suffix: "@sjs.org.hk", permitted_ngo: ["聖雅各福群會惠澤社區藥房"] },
    { suffix: "@hkacs.org.hk", permitted_ngo: ["香港防癌會"] },
    { suffix: "@yot.org.hk", permitted_ngo: ["仁愛堂鄭丁港善心藥物中心"] },
    { suffix: "@pcfhk.org", permitted_ngo: ["香港藥學服務基金配安心藥房"] },
    {
      suffix: "@hk.skhlmc.org",
      permitted_ngo: ["香港聖公會麥理浩夫人中心慈惠社區藥房"],
    },
    {
      suffix: "@iqvia.com",
      permitted_ngo: [
        "聖雅各福群會惠澤社區藥房",
        "仁愛堂鄭丁港善心藥物中心",
        "香港聖公會麥理浩夫人中心慈惠社區藥房",
        "香港藥學服務基金配安心藥房",
        "香港防癌會",
      ],
      permit_all_ngo: true,
    },
    {
      suffix: "@hkapi.hk",
      permitted_ngo: [
        "聖雅各福群會惠澤社區藥房",
        "仁愛堂鄭丁港善心藥物中心",
        "香港聖公會麥理浩夫人中心慈惠社區藥房",
        "香港藥學服務基金配安心藥房",
        "香港防癌會",
      ],
      permit_all_ngo: true,
    },
    {
      suffix: "@hku.hk",
      permitted_ngo: [
        "聖雅各福群會惠澤社區藥房",
        "仁愛堂鄭丁港善心藥物中心",
        "香港聖公會麥理浩夫人中心慈惠社區藥房",
        "香港藥學服務基金配安心藥房",
        "香港防癌會",
      ],
      permit_all_ngo: true,
    },
  ],
  googlePlayURL:
    "https://play.google.com/store/apps/details?id=com.mclinica.hkapi",
  appStoreURL: "https://apps.apple.com/us/app/id1522841869",
};
