import React from "react";
import { useHistory } from "react-router-dom";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonButton,
  IonMenuToggle,
  IonMenuButton,
} from "@ionic/react";
import { Search, ChevronLeft } from "@material-ui/icons";
import "./Header.css";

const Header = ({
  light = false,
  backButton = false,
  backToHome = true,
  backButtonFunction = null,
  searchButton = true,
  title = "",
  noShadow = false,
  responsiveHeader = false,
  profileButton = false,
  className,
  searchAction = null,
}) => {
  let history = useHistory();

  return (
    <IonHeader
      className={
        noShadow
          ? "header-noshadow"
          : responsiveHeader
          ? "responsive-header"
          : null
      }
    >
      <IonToolbar
        className={
          light ? "toolbar-light" : noShadow ? "toolbar-noshadow" : "toolbar"
        }
      >
        <IonButtons slot="start">
          {backButton ? (
            <IonButton
              onClick={
                !!backButtonFunction
                  ? backButtonFunction
                  : backToHome
                  ? () => history.replace("/list")
                  : history.goBack
              }
              color={light ? "dark" : "light"}
            >
              <ChevronLeft className="text-white" />
            </IonButton>
          ) : (
            <IonMenuToggle>
              <IonMenuButton color={light ? "dark" : "light"} />
            </IonMenuToggle>
          )}
        </IonButtons>
        <IonTitle className={light ? "toolbar-title-light" : "toolbar-title"}>
          {title}
        </IonTitle>
        {searchButton && !searchAction && (
          <IonButtons slot="end">
            <IonButton
              routerLink="/search"
              routerDirection="back"
              color={light ? "dark" : "light"}
            >
              <Search />
            </IonButton>
          </IonButtons>
        )}

        {searchAction && (
          <IonButtons slot="end">
            <IonButton onClick={searchAction} color={light ? "dark" : "light"}>
              <Search />
            </IonButton>
          </IonButtons>
        )}
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
