import React, { Component } from "react";
import { connect } from "react-redux";
import { IonPage, IonContent } from "@ionic/react";
import {
  Container,
  Divider,
  Typography,
  withStyles,
  createStyles,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { withMobileView } from "../../components/Mobile";
import SearchHeader from "../../components/Common/SearchHeader.component";
import { BrandDetail } from "../../components/Common";
import { Link } from "react-router-dom";
import Header from "../../common/Header";

const styles = ({ spacing, palette }) =>
  createStyles({
    listHeader: {
      marginTop: spacing(2),
      color: grey[700],
    },
    divider: {
      marginTop: spacing(4),
      marginBottom: spacing(3),
    },
    link: {
      marginTop: 15,
      fontSize: 14,
      textDecoration: "none",
    },
    separator: {
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      color: grey[700],
      marginBottom: "1em",
      "&:before": {
        content: "''",
        flex: 1,
        borderBottom: "1px solid #BBB",
        marginRight: "1em",
      },
      "&:after": {
        content: "''",
        flex: 1,
        borderBottom: "1px solid #BBB",

        marginLeft: "1em",
      },
    },
  });

const ListHeader = withStyles(styles)(({ heading = "", classes }) => {
  return (
    <Typography variant="body2" className={classes.listHeader}>
      {heading}
    </Typography>
  );
});

class SearchPage extends Component {
  state = {
    searchText: "",
    filterValue: null,
  };

  myRef = null;

  scrollUp = () => {
    this.myRef.scrollToTop();
  };

  render() {
    const { filterValue } = this.state;
    const { filteredProgramCatalog } = this.props.programCatalogStore;
    const { classes } = this.props;
    return (
      <IonPage>
        <Header backButton={true} title="Search" searchAction={this.scrollUp} />
        <IonContent
          className="content"
          ref={(ref) => (this.myRef = ref)}
          style={{ height: 300 }}
        >
          <Container>
            <SearchHeader
              filterValue={filterValue}
              onChange={(value) => this.setState({ searchText: value })}
            />
          </Container>
          <Divider className={classes.divider} />
          <Container>
            <ListHeader heading="Search Results" classes={classes} />
            <Link className={classes.link} to="/list">
              Go back to home page
            </Link>
            {filteredProgramCatalog &&
              filteredProgramCatalog.map((brand) => (
                <BrandDetail
                  key={brand.id}
                  brandId={brand.id}
                  brandName={brand.brand_name}
                  genericName={brand.generic_name}
                  specialty={brand.specialty}
                  ngo={brand.ngo}
                />
              ))}
            <div className={classes.separator}>End of Results</div>
          </Container>
        </IonContent>
      </IonPage>
    );
  }
}

const mapStateToProps = (state) => ({
  programCatalogStore: state.programCatalogStore,
});

const mapDispatchToProps = ({ programCatalogStore }) => ({
  ...programCatalogStore,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMobileView(withStyles(styles)(SearchPage)));
