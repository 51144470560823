import React from "react";
import { createStyles, withStyles, CircularProgress } from "@material-ui/core";

const styles = ({ spacing, palette }) =>
    createStyles({
        respContainer: {
            position: "relative",
            overflow: "hidden",
            paddingTop: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        respIframe: {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            border: 0,
        },
        circularProgress: {
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "100%",
            height: "100%",
        },
    });

const UserGuideVideo = ({ classes = {} }) => {
    return (
        <div className={classes.respContainer}>
            <CircularProgress className={classes.circularProgress} />
            <video className={classes.respIframe} preload="metadata" controls playsinline webkit-playsinline="webkit-playsinline">
                <source
                    src="https://storage.googleapis.com/connect-microservice-production/hkapi/assets/videos/PSP%20HKAPI%20Video%204.mp4"
                    type="video/mp4"
                />
            </video>
        </div>
    );
};

export default withStyles(styles)(UserGuideVideo);
