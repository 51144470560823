import React from "react";
import { Plugins } from "@capacitor/core";

import MobileContext from "./context";

const { Device } = Plugins;

const withMobileViewProvider = (Component) => {
	class WithMobileViewProvider extends React.Component {
		state = {
			mobileOS: ["android", "ios"],
			isMobileView: false,
		};

		async componentDidMount() {
			await this.handleWindowSizeChange();

			window.addEventListener("resize", this.handleWindowSizeChange);
		}

		handleWindowSizeChange = async (event) => {
			const { mobileOS } = this.state;
			const { operatingSystem } = await Device.getInfo();

			this.setState({ isMobileView: mobileOS.includes(operatingSystem) });
		};

		render() {
			const { isMobileView } = this.state;

			return (
				<MobileContext.Provider value={isMobileView}>
					<Component {...this.props} />
				</MobileContext.Provider>
			);
		}
	}
	return WithMobileViewProvider;
};

export default withMobileViewProvider;
