import React from "react";
import { IonPage, IonContent } from "@ionic/react";
import { Paper, Typography, createStyles, withStyles } from "@material-ui/core";

import { withMobileView } from "../../components/Mobile";
import Header from "../../common/Header";

import TermsAndConditionsContent from "../../components/StaticContent/TermsAndConditionsContent";

const styles = ({ spacing, palette }) =>
  createStyles({
    root: {
      margin: spacing(2),
      padding: spacing(2),
    },
  });

const TermsAndConditionsPage = ({ classes = {} }) => {
  return (
    <IonPage>
      <Header
        title="Terms and Conditions"
        backButton
        searchButton={false}
        light
      />
      <IonContent className="content">
        <Paper className={classes.root}>
          <Typography variant="body2" paragraph>
            <TermsAndConditionsContent />
          </Typography>
        </Paper>
      </IonContent>
    </IonPage>
  );
};

export default withMobileView(withStyles(styles)(TermsAndConditionsPage));
