import React, { Component } from "react";
import { connect } from "react-redux";
import { Field } from "formik";
import { createStyles, withStyles } from "@material-ui/core";
import _ from "lodash";

import FormikForm from "../../components/FormikForm/FormikForm.component";
import { SelectField } from "../../components/FormFields";
import { generateValidationSchema } from "../../utilities/validationSchema.utility";

const styles = ({ spacing, palette }) =>
  createStyles({
    formikFields: {
      '& label': {
        color: palette.primary.dark,
      }
    },
  }
);

class UpdateProfile extends Component {
  state = {
    profileFields: [],
  };

  async componentDidMount() {
    this.setState({
      profileFields: _.filter(
        this.props.userStore.userFields,
        (field) =>
          _.includes(field.name.toLowerCase(), "workplace") ||
          _.includes(field.name.toLowerCase(), "specialization_area")
      ),
    });
  }

  handleUpdateProfile = async (values, actions) => {
    actions.setSubmitting(true);

    try {
      const payload = values;
      const submitAction = this.props.putUpdateProfile;

      await submitAction(payload);
    } catch (err) {
      console.log(err);
    } finally {
      actions.setSubmitting(false);
    }
  };

  render() {
    const { profileFields } = this.state;
    const { userProfile } = this.props.userStore;
    const { classes } = this.props;

    return (
      <FormikForm
        initialValues={userProfile.metadata}
        onSubmitHandler={this.handleUpdateProfile}
        validationSchema={generateValidationSchema(profileFields)}
        customSubmitText="Save Changes"
      >
        <div
          className={classes.formikFields}
        >
          {profileFields &&
            profileFields.map((field) => (
              <Field
                key={field.name}
                name={field.name}
                component={SelectField}
                options={
                  field.field_type_options &&
                  field.field_type_options.select_dropdown_options
                }
                placeholder={field.placeholder}
                label={field.label || field.title || field.placeholder}
                helperText={field.hint}
                labelClassName={classes.fieldLabel}
              />
            ))}
          </div>
      </FormikForm>
    );
  }
}

const mapStateToProps = (state) => ({
  userStore: state.userStore,
});

const mapDispatchToProps = ({ userStore, uiStore }) => ({
  ...userStore,
  ...uiStore,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UpdateProfile));
