import cookieStoragePlugin from 'cookie-storage-v2';
import 'capacitor-secure-storage-plugin';
import { Plugins } from '@capacitor/core';
import { isPlatform } from '@ionic/react';

const { SecureStoragePlugin } = Plugins;

export const cookieStorage = {
  removeItem(key) {
    return cookieStoragePlugin.removeItem(key);
  },
  getItem(key) {
    let value = cookieStoragePlugin.getItem(key) ?
      cookieStoragePlugin.getItem(key)
      : null;
    
    try {
      JSON.parse(value);
      value = JSON.stringify(value)
    } catch(e) {}

    return value;
  },
  setItem(key, value) {
    if(key.includes("oidc.user")) {
      value = JSON.parse(value);
      delete value.profile;
      delete value.id_token;
      value = JSON.stringify(value);
    }
    cookieStoragePlugin.setItem(key, value, {
      secure: true,
      expires: {
        months: 1
      }
    });
  },
  async clear() {
    cookieStoragePlugin.clear()
  }
}

export const secureStorage = {
  async getItem(key) {
    return SecureStoragePlugin.get({ key })
  },
  async setItem(key, value) {
    SecureStoragePlugin.set({ key, value })
  },
  async removeItem(key) {
    return SecureStoragePlugin.remove({ key });
  },
  async clear() {
    SecureStoragePlugin.clear()
  }
}

export const storage = isPlatform('capacitor') ? secureStorage : cookieStorage;