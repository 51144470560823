import React, { Component } from "react";
import { connect } from "react-redux";
import { IonPage, IonContent } from "@ionic/react";
import {
  Container,
  Button,
  Grid,
  Typography,
  createStyles,
  withStyles,
} from "@material-ui/core";

import { withMobileView } from "../../components/Mobile";

// FORMS
import UserRegistration from "../../components/Forms/UserRegistration.form";

// FORMFIELDS

//CONTENT
import IntroductionContent from "../../components/StaticContent/IntroductionContent";

// IMG
import BackgroundImg from "./../../img/login-desktop.png";
import LogoImg from "./../../img/logo.png";

import AppStoreImgButton from "./../../img/app-store.svg";
import GooglePlayImgButton from "./../../img/google-play.svg";

const styles = ({ spacing, palette }) =>
  createStyles({
    root: {},
    container: {},
    primerBox: {
      backgroundImage: `url(${BackgroundImg})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right center",
      backgroundSize: "cover",
      objectFit: "contain",
      padding: spacing(16),
      position: "fixed",
      left: 0,
      minHeight: "100vh",
      minWidth: "50vw",
    },
    primerLogo: {
      width: 250,
      height: 100,
      marginBottom: spacing(8),
    },
    primerHeader: {
      color: "white",
      marginBottom: spacing(4),
    },
    primerText: {
      color: "white",
      flexWrap: "nowrap",
      display: "flex",
      overflow: "auto",
      width: 400,
      height: 200,
      "&::-webkit-scrollbar": {
        width: 5,
      },
      "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#2D5BC0",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#3770ed",
      },
    },
    formHeader: {
      marginBottom: spacing(4),
    },
    formBox: {
      padding: spacing(16),
      position: "absolute",
      right: 0,
      minHeight: "100vh",
      minWidth: "50vw",
    },
    loginButton: {
      marginTop: spacing(4),
      backgroundColor: palette.primary.dark,
      "&:hover": {
        backgroundColor: "#00174a",
      },
    },
    helperText: {
      display: "block",
      width: "100%",
      textAlign: "center",
      borderBottom: "1px solid #000",
      lineHeight: "0.1em",
      marginTop: spacing(4),
      marginBottom: spacing(4),
    },
    helperTextInner: {
      background: "white",
      padding: "0 10px",
    },
    appLogo: {
      width: 150,
      height: 100,
      marginBottom: spacing(8),
      marginRight: spacing(1),
    },
    appButtons: {
      display: "flex",
    },
  });

class LoginPage extends Component {
  state = {
    showRegisterForm: false,
  };

  handleLogin = () => {
    this.setState({ showRegisterForm: false });
    this.props.loginUser();
  };

  render() {
    const { showRegisterForm } = this.state;
    const classes = this.props.classes;
    return (
      <IonPage>
        <IonContent>
          <Container disableGutters maxWidth={false} className={classes.root}>
            <Grid container className={classes.container}>
              <Grid item xs={6} className={classes.primerBox}>
                <img
                  src={LogoImg}
                  className={classes.primerLogo}
                  alt="HKAPI logo"
                ></img>
                <Typography variant="h4" className={classes.primerHeader}>
                  One Platform Directory
                </Typography>
                <IntroductionContent className={classes.primerText} />
                <div className={classes.appButtons}>
                  <a href={window.config.appStoreURL}>
                    <img
                      src={AppStoreImgButton}
                      className={classes.appLogo}
                      alt="App Store"
                    ></img>
                  </a>
                  <a href={window.config.googlePlayURL}>
                    <img
                      src={GooglePlayImgButton}
                      className={classes.appLogo}
                      alt="Google Play"
                    ></img>
                  </a>
                </div>
              </Grid>
              <Grid item xs={6} className={classes.formBox}>
                <Typography
                  variant="h5"
                  color="primary"
                  className={classes.formHeader}
                >
                  Login to Your HKAPI Account
                </Typography>
                <Button
                  className={classes.loginButton}
                  onClick={this.handleLogin}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                >
                  Login
                </Button>
                <small className={classes.helperText}>
                  <span className={classes.helperTextInner}>
                    New to One Platform Directory
                  </span>
                </small>
                {!showRegisterForm && (
                  <Button
                    onClick={() => this.setState({ showRegisterForm: true })}
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                  >
                    Create Your Account
                  </Button>
                )}
                {showRegisterForm && (
                  <React.Fragment>
                    <Typography
                      variant="h5"
                      color="primary"
                      className={classes.formHeader}
                    >
                      Register for your HKAPI account with your business email
                      address
                    </Typography>
                    <UserRegistration history={this.props.history} />
                  </React.Fragment>
                )}
              </Grid>
            </Grid>
          </Container>
        </IonContent>
      </IonPage>
    );
  }
}

const mapDispatchToProps = ({ authStore }) => ({
  ...authStore,
});

export default connect(
  null,
  mapDispatchToProps
)(withMobileView(withStyles(styles)(LoginPage)));
