import { init } from "@rematch/core";

const store = init({
  models: {
    programCatalogStore: require("./programCatalog.model").default,
    userStore: require("./user.model").default,
    authStore: require("./auth.model").default,
    uiStore: require("./ui.model").default,
    programStore: require("./program.model").default
  }
});

export default store;
