import { AppVersion } from "@ionic-native/app-version";
import { Market } from "@ionic-native/market";
import { Plugins } from "@capacitor/core";
import axios from "axios";

import stores from "../models/index.model";

const { Network, Modals, Device } = Plugins;
const mobileOS = ["android", "ios"];

export default {
	checkApplicationUpdate: async () => {
		try {
			// RETRIEVE current OS
			const { operatingSystem: currentOS } = await Device.getInfo();

			if (mobileOS.includes(currentOS)) {
				// CHECK connection status
				const networkStatus = await Network.getStatus();

				if (networkStatus.connected) {
					stores.dispatch.uiStore.showLoading(true);

					// RETRIEVE current app version
					const currentAppVersion = await AppVersion.getVersionNumber();

					// RETRIEVE latest app version available via API
					const { result: latestVersion } = (
						await axios.get(`${window.config.API_URL}/version`)
					).data;
					const latestAppVersion =
						latestVersion[`${currentOS}_version`];

					// FORCE UPDATE
					if (currentAppVersion < latestAppVersion) {
						const packageName = currentOS === 'android' ? "com.mclinica.hkapi" : "id1522841869";

						// SHOW & OPEN app store for latest app version
						await Modals.alert({
							title: "Application Update",
							message: `New version is available. Tap 'OK' to proceed.`,
						});
						
						Market.open(packageName);
					}
					stores.dispatch.uiStore.showLoading(false);
				}
			}
		} catch (err) {
			console.log(err.message);
			stores.dispatch.uiStore.showLoading(false);
			stores.dispatch.uiStore.showToast({
				message: err.message,
				position: "bottom",
				color: "warning",
			});
			throw err;
		}
	},
};
