import { userService } from "../services/index.service";
import stores from "./index.model";
import generateErrorMessage from "../utilities/errorMessage.utility";

export default {
  state: {
    userProfile: {
      campaign_id: "",
      email: "",
      email_verified_at: null,
      first_name: "",
      full_name: "",
      id: "",
      is_registration_completed: false,
      is_tester: false,
      last_name: "",
      metadata: {},
      middle_name: "",
      mobile_number: "",
      mobile_verified_at: null,
      program_id: "",
      push_key: "",
      status: "",
      user_type: "",
      created_at: new Date(),
      updated_at: new Date(),
      deleted_at: null,
    },
    registeredUserToken: {
      access_token: "",
      refresh_token: "",
    },
    userFields: [],
  },

  reducers: {
    setUserProfile(state, payload) {
      return {
        ...state,
        userProfile: payload,
      };
    },
    setRegisteredUserToken(state, payload) {
      return {
        ...state,
        registeredUserToken: payload,
      };
    },
    resetRegisteredUserToken(state) {
      return {
        ...state,
        registeredUserToken: {
          access_token: "",
          refresh_token: "",
        },
      };
    },
    setUserFields(state, payload) {
      return {
        ...state,
        userFields: payload,
      };
    },
  },

  effects: (dispatch) => ({
    getUserProfile: async () => {
      try {
        //stores.dispatch.uiStore.showLoading(true);
        const { result } = await userService.getUserProfile();

        dispatch.userStore.setUserProfile(result);
        //stores.dispatch.uiStore.showLoading(false);
      } catch (err) {
        console.error(err);
        stores.dispatch.uiStore.showLoading(false);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: "warning",
        });
        throw err;
      }
    },

    getUserFields: async () => {
      try {
        const userFields = await userService.getUserFields();

        dispatch.userStore.setUserFields(userFields);
      } catch (err) {
        console.error(err);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: "warning",
        });
        throw err;
      }
    },

    getUserData: async () => {
      try {
        //stores.dispatch.uiStore.showLoading(true);
        const { result } = await userService.getUserProfile();
        let bookmarks = await window.db.hkapi.get("bookmarks");
        let recentlyViewed = await window.db.hkapi.get("recently_viewed");
        // UPDATE recently viewed brands data
        await window.db.hkapi.put({
          _id: "recently_viewed",
          _rev: recentlyViewed._rev,
          catalog: result.metadata.recently_viewed
            ? result.metadata.recently_viewed
            : recentlyViewed.catalog,
        });

        // UPDATE recently viewed brands data
        await window.db.hkapi.put({
          _id: "bookmarks",
          _rev: bookmarks._rev,
          catalog: result.metadata.bookmarks
            ? result.metadata.bookmarks
            : bookmarks.catalog,
        });
        //stores.dispatch.uiStore.showLoading(false);
      } catch (err) {
        console.error(err);
        stores.dispatch.uiStore.showLoading(false);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: "warning",
        });
        throw err;
      }
    },

    syncUserData: async () => {
      try {
        //stores.dispatch.uiStore.showLoading(true);
        let { result } = await userService.getUserProfile();
        let bookmarks = await window.db.hkapi.get("bookmarks");
        let recentlyViewed = await window.db.hkapi.get("recently_viewed");
        result.metadata.recently_viewed = recentlyViewed.catalog;
        result.metadata.bookmarks = bookmarks.catalog;

        const { resultGet } = await userService.putUpdateProfile(
          result.metadata
        );

        //stores.dispatch.uiStore.showLoading(false);
      } catch (err) {
        console.error(err);
        stores.dispatch.uiStore.showLoading(false);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: "warning",
        });
        throw err;
      }
    },

    postRegisterUser: async (payload) => {
      try {
        stores.dispatch.uiStore.showLoading(true);
        const { message, result } = await userService.postRegisterUser({
          ...payload,
          user_type: window.config.USER_TYPE,
        });

        dispatch.userStore.setRegisteredUserToken({
          access_token: result.access_token,
          refresh_token: result.refresh_token,
        });
        const loggedinUser = window.apiClient.setLoggedInUser(
          {
            accessToken: result.access_token,
            refreshToken: result.refresh_token,
          },
          true
        );
        dispatch.authStore.setLoggedUser(loggedinUser);
        stores.dispatch.uiStore.showLoading(false);
        console.log(message, result);
      } catch (err) {
        console.log(err);
        stores.dispatch.uiStore.showLoading(false);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: "warning",
        });
        throw err;
      }
    },

    postVerifyUser: async (payload) => {
      try {
        stores.dispatch.uiStore.showLoading(true);
        const { message } = await userService.postVerifyUser(payload);

        dispatch.userStore.resetRegisteredUserToken();
        await stores.dispatch.userStore.getUserProfile();
        stores.dispatch.uiStore.showLoading(false);
        stores.dispatch.uiStore.showToast({
          message,
          color: "success",
        });
      } catch (err) {
        console.log(err);
        stores.dispatch.uiStore.showLoading(false);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: "warning",
        });
        throw err;
      }
    },

    postResendVerification: async ({ token }) => {
      try {
        const { message } = await userService.postResendVerification();

        stores.dispatch.uiStore.showToast({
          message,
          color: "success",
        });
      } catch (err) {
        console.log(err);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: "warning",
        });
        throw err;
      }
    },

    putUpdateProfile: async (payload) => {
      try {
        const { result } = await userService.putUpdateProfile(payload);

        dispatch.userStore.setUserProfile(result);
        stores.dispatch.uiStore.showToast({
          message: "Your profile has been updated.",
          color: "success",
        });
      } catch (err) {
        console.error(err);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: "warning",
        });
        throw err;
      }
    },

    putUpdatePassword: async (payload) => {
      try {
        const { message } = await userService.postUpdatePassword(payload);

        stores.dispatch.uiStore.showToast({
          message: "Your password has been updated.",
          color: "success",
        });
      } catch (err) {
        console.error(err);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: "warning",
        });
        throw err;
      }
    },

    postDeactivateAccount: async () => {
      try {
        const { message } = await userService.postDeactivateAccount();

        stores.dispatch.uiStore.showToast({
          message,
          color: "success",
        });
      } catch (err) {
        console.error(err);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: "warning",
        });
        throw err;
      }
    },
  }),
};
