import React from "react";
import { IonPage, IonContent } from "@ionic/react";
import { Paper, createStyles, withStyles } from "@material-ui/core";

import { withMobileView } from "../../components/Mobile";
import Header from "../../common/Header";
import AboutContent from "./../../components/StaticContent/AboutContent";

const styles = ({ spacing, palette }) =>
  createStyles({
    root: {
      margin: spacing(2),
      padding: spacing(2),
    },
  });

const AboutPage = ({ classes = {} }) => {
  return (
    <IonPage>
      <Header backButton={true} title="About HKAPI" light />
      <IonContent className="content">
        <Paper className={classes.root}>
          <AboutContent />
        </Paper>
      </IonContent>
    </IonPage>
  );
};

export default withMobileView(withStyles(styles)(AboutPage));
