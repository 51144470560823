import React from "react";
import { Typography, createStyles, withStyles } from "@material-ui/core";

const styles = ({ spacing, palette }) =>
  createStyles({
    header: {
      fontWeight: 700,
      marginBottom: spacing(2),
    },
    terms: {
      margin: spacing(2),
      padding: spacing(2),
    },
    noBullets: {
      listStyleType: "none",
    },
    indent: {
      textIndent: "2em",
    },
  });

const AboutContent = ({ classes = {} }) => {
  return (
    <div>
      <Typography variant="h6" color="primary" className={classes.header}>
        Terms and Conditions
      </Typography>
      <Typography
        className={classes.terms}
        variant="body2"
        align="justify"
        paragraph
      >
        <b>HCP Terms and Conditions</b>
        <br />
        <br />
        <p>1. &nbsp; INTRODUCTION</p>

        <p>
          1.1 &nbsp; This HKAPI Online Platform App (the “<b>App</b>”) is
          published by or on behalf of the Hong Kong Association of the
          Pharmaceutical Industry (“<b>HKAPI</b>” or “<b>We</b>” or “Us of Room
          906-7, 9/F., K. Wah Centre, 191 Java Road, North Point, Hong Kong
        </p>

        <p>
          1.2 &nbsp; The App provides users, including but not limited to health
          care professionals (HCP) in Hong Kong with the following key features:
        </p>
        <ul>
          <li>HCP enrolment;</li>
          <li>HCP registration; and</li>
          <li>
            a directory of patient support programs (PSP) in Hong Kong,
            including information on disease and therapeutic areas;
            pharmaceutical products, and participating NGOs.
          </li>
        </ul>

        <p>
          1.3 &nbsp; By downloading, installing or otherwise accessing the App
          you agree unconditionally to be bound by the following terms and
          conditions (“<b>Terms</b>“) and our privacy policy and our cookies
          policy. If you have any queries about the App or these Terms, you can
          contact Us by any of the means set out in [paragraph 11] of these
          Terms. If you do not agree with these Terms, you should stop using the
          App immediately and uninstall the App from your device.
        </p>

        <p>
          1.4. &nbsp; Use of the App does not include the provision of a mobile
          device or other necessary equipment to access it. To use the App you
          will require Internet connectivity and appropriate telecommunication
          links. HKAPI shall not have any responsibility or liability for any
          telephone or other costs you may incur.
        </p>

        <p>2. &nbsp; LICENSE TO USE: </p>

        <p>
          2.1 &nbsp; Subject to your agreement with these Terms, we grant to you
          a free, non-exclusive, non-transferable, non-sharable, revocable,
          limited license to use the App solely for your own personal use in
          accordance with these Terms. The App must not be used for any
          commercial purpose whatsoever or for any illegal or unauthorised
          purpose.
        </p>

        <p>3. &nbsp; GENERAL RULES RELATING TO CONDUCT: </p>

        <p>
          3.1 &nbsp; You agree that when you use the App you shall comply with
          all applicable Hong Kong and international laws, including the local
          laws in your country of residence, and with these Terms. In
          particular, but without limitation, you agree not to:
        </p>

        <ul>
          <li>
            Use the App in any unlawful manner or in a manner which promotes or
            encourages illegal activity including (without limitation) copyright
            infringement; or
          </li>

          <li>
            Use or attempt to use another user’s account to access to the App
            without authorization from that user and HKAPI; or
          </li>

          <li>
            Attempt to gain unauthorised access to the App, or any software used
            in the provision of the App, or any networks, servers or computer
            systems connected to the App; or
          </li>

          <li>
            Modify, adapt, translate or reverse engineer any part of the App or
            re-format or frame any portion of the pages comprising the App, save
            to the extent expressly permitted by these Terms or by law; or
          </li>

          <li>
            Develop or use any third-party applications that interact with the
            App without our prior written consent, including any scripts
            designed to scrape or extract data from the App.
          </li>
        </ul>

        <p>
          3.2 &nbsp; If anyone brings a claim against HKAPI as a result of your
          use of the App otherwise than in accordance with these Terms or
          Applicable Laws, you agree to indemnify HKAPI in full and on demand
          and hold HKAPI harmless from and against any loss, damage, costs or
          expenses of any kind (including reasonable legal fees and costs) which
          it may incur directly or indirectly in relation to such claim.
        </p>

        <p>
          3.3 &nbsp; This App is intended solely for use by Health Care
          Professionals and upon such basis you confirm and agree that:-
        </p>

        <ul>
          <li>
            The App provides information, content and/or data (collectively,
            “Information”) for informational purposes only and is not intended
            for use as a substitute for medical advice by a registered
            healthcare practitioner.
          </li>
          <li>
            Using, accessing and/or browsing the App does not create a
            physician-patient relationship between you and any patient
          </li>
          <li>
            Nothing contained in the App is intended to create a
            physician-patient relationship, to replace the services of a
            licensed, trained physician or health professional or to be a
            substitute for medical advice of a physician or trained health
            professional.
          </li>
          <li>
            You should not rely on anything contained in the App, and shall as
            required consult a licensed physician in relation to all such
            matters and information contained therein.
          </li>
          <li>
            You shall not make any health or medical related decision based in
            whole or in part on anything contained in the App.
          </li>
        </ul>

        <p>4. &nbsp; CONTENT</p>

        <p>
          4.1 &nbsp; The copyright and other forms of intellectual property in
          all material contained on, in, or available through the App including
          all information, data, text, music, sound, photographs, graphics and
          video messages, the selection and arrangement thereof, and all source
          code, software compilations and other material (“<b>Material</b>“) is
          owned by or licensed to HKAPI. All rights are reserved. You can view,
          print or download extracts of the Material for your own personal use
          or distribute to your patient but you cannot otherwise copy, edit,
          vary, reproduce, publish, display, distribute, store, transmit,
          commercially exploit, disseminate in any form whatsoever or use the
          Material without HKAPI’s express permission.
        </p>

        <p>
          4.2 &nbsp; The trademarks, service marks, and logos (“
          <b>Trade Marks</b>“) contained on or in the App are owned by HKAPI or
          third party partners of HKAPI. You cannot use, copy, edit, vary,
          reproduce, publish, display, distribute, store, transmit, commercially
          exploit or disseminate the Trade Marks without the prior written
          consent of HKAPI or the relevant third party partner of HKAPI.
        </p>

        <p>5. &nbsp; LINK TO THIRD PARTIES</p>

        <p>
          5.1 &nbsp; The App may contain links to websites operated by third
          parties (“<b>Third Party Websites</b>“). Notwithstanding such links,
          HKAPI does not have any influence or control over any such Third Party
          Websites and, unless otherwise stated, is not responsible for and does
          not endorse any Third Party Websites or their availability or
          contents.
        </p>

        <p>
          5.2 HKAPI will not have or accept any liability, obligation or
          responsibility whatsoever for any loss, destruction or damage
          (including without limitation consequential loss, destruction or
          damage) howsoever arising from or in respect of your use or misuse of
          or reliance on, or inability to use any such links to Third Party
          Websites or your use or reliance on the contents of any such Third
          Party Websites. You should refer to the terms and conditions of each
          such Third Party Websites when using that website.
        </p>

        <p>
          6.&nbsp;
            LIMITATION OF LIABILITY, WAIVER AND INDEMNIFICATION OF CLAIMS, AND
            NO WARRANTIES
        </p>

        <p>
          6.1&nbsp; <u>LIMITATION OF LIABILITY</u>
        </p>

        <p>
            IN NO EVENT WILL HKAPI BE LIABLE TO YOU OR ANY OTHER PERSON FOR ANY:
        </p>

        <ul className={classes.noBullets}>
          <li>
              (A) &nbsp; ECONOMIC LOSS OR DAMAGE, INCLUDING, WITHOUT LIMITATION,
              LOSS OF DATA, REVENUES, PROFITS, CONTRACTS, BUSINESS, OR
              ANTICIPATED SAVINGS;
          </li>
          <li>
            (B) &nbsp; LOSS OF GOODWILL OR REPUTATION; AND/OR
          </li>
          <li>
              (C) &nbsp; SPECIAL, INCIDENTAL, EXEMPLARY OR CONSEQUENTIAL LOSS OR
              DAMAGE
          </li>
        </ul>

        <p>
            ARISING FROM YOUR OR ANY OTHER PERSON'S USE OR INABILITY TO USE THE
            APP, OR FROM INFORMATION CONTAINED WITHIN OR ACCESSED FROM THE APP,
            WHETHER SUCH LOSS OR DAMAGE IS BASED UPON ANY STATUTE, COMMON LAW,
            WARRANTY, CONTRACT, TORT OR OTHER LEGAL THEORY.
        </p>

        <p>
          6.2 &nbsp; <u>WAIVER AND INDEMNIFICATION OF CLAIMS</u>
        </p>

        <p>
            YOU WAIVE AND COVENANT NOT TO ASSERT ANY CLAIMS OR ALLEGATIONS OF
            ANY NATURE WHATSOEVER ARISING FROM OR RELATING TO YOUR USE OF THE
            APP, INCLUDING, WITHOUT LIMITATION, ALL CLAIMS AND ALLEGATIONS
            RELATING TO ANY ALLEGED INFRINGEMENT OF PROPRIETARY RIGHTS, ANY
            ALLEGED INACCURACY OF THE APP CONTENT, OR ALLEGATIONS THAT HKAPI HAS
            OR SHOULD INDEMNIFY, DEFEND OR HOLD YOU HARMLESS FROM ANY CLAIM OR
            ALLEGATION ARISING FROM YOUR USE OF THE APP. YOU AGREE THAT YOUR USE
            OF THE APP IS AT YOUR OWN RISK AND NEITHER HKAPI NOR ANY PERSON WHO
            PROVIDES INFORMATION OR OTHER MATERIAL FOR USE WITH THE APP SHALL BE
            LIABLE FOR ANY LOSS OR DAMAGE OF ANY SORT ARISING FROM YOUR USE OF
            THE APP.
        </p>

        <p>
          6.3 &nbsp; <u>NO WARRANTIES</u>
        </p>

        <p>
            YOU UNDERSTAND AND AGREE THAT THE APP IS PROVIDED ON AN “AS IS” AND
            "AS-AVAILABLE" BASIS, WITH NO WARRANTY. THE PARTIES AGREE THAT ANY
            AND ALL WARRANTIES, EXPRESS OR IMPLIED ARE EXCLUDED, INCLUDING
            (WITHOUT LIMITATION) ANY WARRANTY THAT THE APP AND INFORMATION OR
            OTHER MATERIAL ON THE APP:
        </p>

        <ul className={classes.noBullets}>
          <li>
              (A) &nbsp; IS CORRECT, COMPLETE, ACCURATE, UP-TO-DATE, OR FIT FOR
              A PARTICULAR PURPOSE;
          </li>
          <li>
              (B) &nbsp; DOES NOT INFRINGE ANY INTELLECTUAL PROPERTY, INCLUDING
              ANY PATENT, TRADE MARK, COPYRIGHT OR OTHER INTERLLECTUAL PROPERTY
              OF ANY KIND;
          </li>
          <li>
              (C) &nbsp; IS AVAILABLE TO USE, OPERATE OR ACCESS AT ANY TIME, OR
              WITHOUT INTERRUPTION OR ERROR-FREE, OR THAT SUCH MAY BE RESOLVED
              OR ANY ERRORS CORRECTED; OR
          </li>
          <li>
            (D) WILL BE COMPATIBLE WITH ALL HARDWARE AND SOFTWARE USED.
          </li>
        </ul>

        <p>
            WITHOUT PREJUDICE TO THE FOREGOING, HKAPI ACCEPT NO RESPONSIBILITY
            FOR ANY TECHNICAL FAILURE OF THE INTERNET AND/OR THE APP, OR ANY
            DAMAGE OR INJURY TO USERS OR THEIR EQUIPMENT AS A RESULT OF OR
            RELATING TO THEIR DOWNLOAD, INSTALLATION AND USE OF THE APP. HKAPI
            MAY CHANGE THE APP OR OTHER MATERIAL DESCRIBED OR CONTAINED IN THE
            APP AT ANY TIME WITHOUT NOTICE.
        </p>

        <p>
          6.4 &nbsp; Nothing in these Terms shall be construed as excluding or
          limiting the liability of HKAPI for death or personal injury caused by
          its negligence or for any other liability which cannot be excluded
          under Hong Kong law.
        </p>

        <p>7. &nbsp; SERVICE SUSPENSION</p>

        <p>
          7.1 &nbsp; Without limiting any other provision of these Terms, HKAPI
          reserves the right to suspend or cease providing any services relating
          to the App, with or without notice, and shall have no liability or
          responsibility to you in any manner whatsoever if it chooses to do so.
        </p>

        <p>8. &nbsp; TERMINATION</p>

        <p>
          8.1 &nbsp; If you are in breach of any of these Terms, HKAPI reserves
          the right, in its sole discretion and without warning, to terminate
          your right to access or use the App. HKAPI is not responsible for any
          loss, damage or harm related to your inability to access or use the
          App based on such termination.
        </p>

        <p>9. &nbsp; CANCELLATION</p>

        <p>
          9.1 &nbsp; You may cancel your account at any time by sending a
          written notice to HKAPI using any of the contact means as set out in
          paragraph [11]. Cancellation will be effective within [7] working days
          after receiving your written notice.
        </p>

        <p>10. &nbsp; GENERAL</p>

        <p>
          10.1 &nbsp; These Terms (as amended from time to time) constitute the
          entire agreement between you and HKAPI concerning your use of the App.
        </p>

        <p>
          10.2 &nbsp; HKAPI reserves the right, at its sole discretion, make
          changes to these Terms from time to time. If it does so, the current
          Terms will be made available by a link in the App to this page. Unless
          otherwise specified, the amended Terms will be effective immediately.
          You are responsible for regularly reviewing these Terms so that you
          are aware of any changes to them and you will be bound by the new
          amended Terms upon your continued use of the App.
        </p>

        <p>
          10.3 &nbsp; These Terms shall be governed by and construed in
          accordance with Hong Kong law and you agree to submit to the exclusive
          jurisdiction of the Hong Kong Courts.
        </p>

        <p>
          10.4 &nbsp; If any provision of these Terms is held by a court of
          competent jurisdiction to be invalid or unenforceable, then such
          provision shall be construed, as nearly as possible, to reflect the
          intentions of the parties (as reflected in the provision) and all
          other provisions shall remain in full force and effect.
        </p>

        <p>
          10.5 &nbsp; HKAPI’s failure to exercise or enforce any right or
          provision of these Terms shall not constitute a waiver of such right
          or provision unless acknowledged and agreed to by HKAPI in writing.
        </p>

        <p>11. &nbsp; CONTACT INFORMATION</p>
        <p>
          HKAPI can be contacted at:
          <p>Email: info@hkapi.hk</p>
          <p>
            Postal Address: Room 906-7, 9/F., K. Wah Centre, 191 Java Road,
            North Point, Hong Kong
          </p>
        </p>

        <p>12. &nbsp; PRIVACY</p>

        <p>
          12.1 &nbsp; IQVIA Solutions Asia Pte Ltd. (the “<b>Company</b>”), is the service
          provider of the App. The Company collects and stores your personal
          information (such as name, e-mail address, work location) for any of
          the following purposes:
        </p>

        <ul>
          <li>To provide you with the ability to access and use the App; </li>
          <li>
            To keep you informed/up-to-date on available patient support
            programs;
          </li>
          <li>
            To answer any queries users may have regarding the patient support
            programs; or
          </li>
          <li>To comply with all applicable laws. </li>
        </ul>

        <p>
          12.2 &nbsp; It may be necessary for the Company to share or disclose
          the personal information collected with affiliates, partners,
          healthcare professionals, and regulatory authorities of the Company
          located both within and outside of your home country for the purposes
          as set out above.
        </p>

        <p>
          12.3 &nbsp; In accordance with applicable law, users have a right to
          access, correct and limit the Company's processing of their personal
          information and sensitive personal information. To exercise such right
          or to ask the Company any questions regarding our use of user's
          personal information please contact the PSP Link Support team by
          sending an email to hkapi.support@psplink.com.
        </p>

        <p>
          12.4 &nbsp; Please note that it is necessary for the Company to
          process your personal information as described above, without which
          you may not be able to access the App or otherwise enjoy the benefits
          of the information in this App
        </p>

        <p className={classes.indent}>
          I hereby consent to the processing of my personal information in the
          manner described above.
        </p>

        <p>These Terms were most recently updated on 10/05/2022.</p>

        <br />
      </Typography>
    </div>
  );
};

export default withStyles(styles)(AboutContent);
