import React from "react";
import { Typography, withStyles } from "@material-ui/core";

const IntroductionContent = ({ className }) => {
  return (
    <Typography variant="body2" className={className} paragraph>
      “One Platform” of Patient Support Programs (PSPs)
      <br />
      <br />
      Patient Support Programs or Patient Assistance Programs (PSP) in this
      platform refer to programs initiated primarily by pharmaceutical companies
      with the aim to help patients to access to innovative medicines through
      various mechanisms, for example, providing eligible patients with price
      discount, refund or with free products etc
      <br />
      <br />
      Centralized platform of PSPs established by the Hong Kong Association of
      the Pharmaceutical Industry (HKAPI)
      <br />
      <br />
      Consolidate PSPs from key pharmaceutical companies in Hong Kong
      <br />
      <br />
      Fast access to most updated information of PSPs
      <br />
      <br />
      Easy and systematic way to get all details of PSPs anytime and anywhere
      <br />
      <br />
      The One Platform Directory is made possible by the sponsorship of the
      following HKAPI member companies:
      <br />
      <br />
      AbbVie Limited
      <br />
      Amgen Hong Kong Limited
      <br />
      AstraZeneca Hong Kong Limited
      <br />
      Boehringer Ingelheim (HK) LTD
      <br />
      Bristol-Myers Squibb Pharma (HK) Limited
      <br />
      Eisai (HK) CO LTD
      <br />
      Janssen, a division of Johnson & Johnson (HK) LTD
      <br />
      Novartis Pharmaceuticals (HK) LTD
      <br />
      Takeda Pharmaceuticals (Hong Kong) Limited
      <br />
      Roche Hong Kong Limited
    </Typography>
  );
};

export default IntroductionContent;
