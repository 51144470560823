import React from "react";
import { connect } from "react-redux";
import { Plugins } from "@capacitor/core";
import {
  Divider,
  List,
  Box,
  Link,
  Typography,
  createStyles,
  withStyles,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Button,
} from "@material-ui/core";
import {
  Home,
  AccountCircle,
  Info,
  PlayCircleFilled,
  LiveHelp,
  ExitToApp,
  Gavel,
} from "@material-ui/icons";
import { grey, blue } from "@material-ui/core/colors";

// IMG
import LogoImg from "./../../img/logo.png";

import LinkItem from "./LinkItem.component";

const styles = ({ spacing, palette }) =>
  createStyles({
    root: {
      minHeight: "100vh",
      color: "white",
      background: "linear-gradient(#014A90, #012169)",
      fallbacks: {
        background: "#014A90",
      },
    },
    contactBox: {
      padding: spacing(2),
    },
    icon: {
      color: grey[700],
    },
    logo: {
      height: 150,
      paddingTop: spacing(2),
      paddingRight: spacing(4),
      paddingBottom: spacing(2),
      paddingLeft: spacing(4),
    },
    "@media (max-width: 767.98px)": {
      logo: {
        height: 120,
        paddingTop: spacing(1),
        paddingRight: spacing(4),
        paddingBottom: spacing(1),
        paddingLeft: spacing(4),
      },
    },
    linkHeader: {
      color: "white",
      textDecoration: "underline",
    },
    links: {
      color: "white",
      textDecoration: "underline",
    },
    divider: {
      background: "white",
      opacity: 0.1,
    },
    logoutHeader: {
      textAlign: "center",
    },
    logoutButton: {
      textTransform: "capitalize",
    },
    dialogText: {
      color: "#000",
    },
  });

const { Browser } = Plugins;

const getHTML = async (htmlPage) => {
  switch (htmlPage) {
    case "about":
      await Browser.open({
        url:
          "https://storage.googleapis.com/connect-microservice-production/hkapi/assets/documents/about.html",
      });
      break;

    case "user_guide":
      await Browser.open({
        url:
          "https://storage.googleapis.com/connect-microservice-production/hkapi/assets/documents/user_guide.html",
      });
      break;

    case "frequently_asked_questions":
      await Browser.open({
        url:
          "https://storage.googleapis.com/connect-microservice-production/hkapi/assets/documents/frequently_asked_questions.html",
      });
      break;

    case "terms_and_conditions":
      await Browser.open({
        url:
          "https://storage.googleapis.com/connect-microservice-production/hkapi/assets/documents/terms_and_conditions.html",
      });
      break;
    default:
      break;
  }
};

const SidebarMenu = ({ classes = {}, logoutUser }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <Box className={`${classes.root} no-print`}>
        <Box>
          <img className={classes.logo} src={LogoImg} alt="HKAPI logo" />
        </Box>
        <List component="nav">
          <LinkItem to="/list" label="Home" icon={<Home />} />
          <LinkItem to="/profile" label="Profile" icon={<AccountCircle />} />
        </List>
        <Divider className={classes.divider} />
        <List component="nav">
          <LinkItem
            to="/about"
            //onClick={() => getHTML("about")}
            label="About HKAPI"
            icon={<Info />}
          />
          <LinkItem
            to="/user-guide"
            //onClick={() => getHTML("user_guide")}
            label="User Guide"
            icon={<PlayCircleFilled />}
          />
          <LinkItem
            to="/frequently-asked-questions"
            //onClick={() => getHTML("frequently_asked_questions")}
            label="FAQ"
            icon={<LiveHelp />}
          />
          <LinkItem
            to="/terms-and-conditions"
            //onClick={() => getHTML("terms_and_conditions")}
            label="Terms & Conditions"
            icon={<Gavel />}
          />
        </List>
        <Divider className={classes.divider} />
        <Box className={classes.contactBox}>
          <Typography className={classes.linkHeader}>Contact Us</Typography>
          <Typography>
            <Link href="tel:+852 2528 3061" className={classes.links}>
              +852 2528 3061
            </Link>
          </Typography>
          <Typography>
            <Link href="mailto:info@hkapi.hk" className={classes.links}>
              info@hkapi.hk
            </Link>
          </Typography>
        </Box>
        <Divider className={classes.divider} />
        <List component="nav">
          <LinkItem
            onClick={handleClickOpen}
            label="Logout"
            icon={<ExitToApp />}
          />
        </List>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.logoutHeader} id="alert-dialog-title">
          <b>HKAPI</b>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className={classes.dialogText}
            id="alert-dialog-description"
          >
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            className={classes.logoutButton}
          >
            No
          </Button>
          <Button
            onClick={logoutUser}
            color="primary"
            variant="contained"
            className={classes.logoutButton}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const mapDispatchToProps = ({ authStore }) => ({
  ...authStore,
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(SidebarMenu));
