export default {
  state: {
    isLoading: false,
    toastConfig: {
      isOpen: false,
      onDidDismiss: null,
      message: "",
      duration: 5000,
      position: "bottom"
    }
  },
  reducers: {
    setLoadingAction(state, payload) {
      return {
        ...state,
        isLoading: payload
      };
    },
    setToastConfig(state, payload) {
      return {
        ...state,
        toastConfig: {
          ...state.toastConfig,
          ...payload
        }
      };
    }
  },
  effects: dispatch => ({
    showLoading(payload) {
      dispatch.uiStore.setLoadingAction(payload);
    },

    showToast(payload) {
      dispatch.uiStore.setToastConfig({ isOpen: false });
      dispatch.uiStore.setToastConfig({
        isOpen: true,
        ...payload
      });
    }
  })
};
