import { Plugins } from '@capacitor/core';
import { Printer } from '@ionic-native/printer';
const { App, Device } = Plugins;

export default async (content) => {
  const { operatingSystem, platform } = await Device.getInfo();

  let printOpts = {
     name: 'HKAPI Program Information',
     duplex: 'short',
     orientation: 'portrait',
     monochrome: false
  }

  if (["android", "ios"].includes(operatingSystem) && platform !== "web") {
    try {
      return Printer.print(content, printOpts)
    } catch(e) {
      console.error(e);
    }
  } else {
    return window.print();
  }
}
