import React, { Component } from "react";
import { connect } from "react-redux";
import { IonPage, IonContent } from "@ionic/react";
import {
  Container,
  Typography,
  withStyles,
  createStyles,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import Header from "../../common/Header";
import { withMobileView } from "../../components/Mobile";
import { BrandDetail } from "../../components/Common";

const styles = ({ spacing, palette }) =>
  createStyles({
    gridList: {
      flexWrap: "nowrap",
      transform: "translateZ(0)",
    },
    listHeader: {
      marginTop: spacing(2),
      color: grey[700],
    },
  });

const ListHeader = withStyles(styles)(({ heading = "", classes }) => {
  return (
    <Typography variant="body2" className={classes.listHeader}>
      {heading}
    </Typography>
  );
});

class ViewPage extends Component {
  state = {};

  async componentDidMount() {
    await this.props.getProgramCatalog();
    await this.props.getRecentlyViewed();
    await this.props.getBookmarks();
  }

  render() {
    const { recentlyViewed, bookmarks } = this.props.programCatalogStore;

    let viewType;
    let brandsToView;

    //Check if URL is recently viewed or bookmarks
    if (this.props.location.pathname.indexOf("/recently-viewed") >= 0) {
      viewType = "recently-viewed";
      brandsToView = recentlyViewed;
    } else if (this.props.location.pathname.indexOf("/bookmarks") >= 0) {
      viewType = "bookmarks";
      brandsToView = bookmarks;
    }

    return (
      <IonPage>
        <Header backButton={true} title="Home" searchButton={false} />
        <IonContent className="content">
          <Container>
            <ListHeader
              heading={
                viewType === "bookmarks" ? "Bookmarks" : "Recently Viewed"
              }
              classes={this.props.classes}
            />
            {brandsToView &&
              brandsToView.map((brand) => (
                <BrandDetail
                  key={brand.id}
                  brandId={brand.id}
                  brandName={brand.brand_name}
                  genericName={brand.generic_name}
                  specialty={brand.specialty}
                  ngo={brand.ngo}
                />
              ))}
          </Container>
        </IonContent>
      </IonPage>
    );
  }
}

const mapStateToProps = (state) => ({
  programCatalogStore: state.programCatalogStore,
});

const mapDispatchToProps = ({ programCatalogStore }) => ({
  ...programCatalogStore,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMobileView(withStyles(styles)(ViewPage)));
