import React, { Component } from "react";
import { connect } from "react-redux";
import { IonPage, IonContent } from "@ionic/react";
import {
  Container,
  Typography,
  withStyles,
  createStyles,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { withMobileView } from "../../components/Mobile";
import SearchHeader from "../../components/Common/SearchHeader.component";
import { BrandDetail } from "../../components/Common";
import { Link } from "react-router-dom";
import Header from "../../common/Header";

const styles = ({ spacing, palette }) =>
  createStyles({
    gridList: {
      display: "flex",
      overflow: "auto",
      flexWrap: "wrap",
    },
    gridTile: {
      width: "280px",
      marginRight: spacing(2),
    },
    listHeader: {
      marginTop: spacing(2),
      color: grey[700],
    },
    link: {
      marginTop: 10,
      fontSize: 14,
      textDecoration: "none",
    },

    separator: {
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      color: grey[700],
      marginBottom: "1em",
      "&:before": {
        content: "''",
        flex: 1,
        borderBottom: "1px solid #BBB",
        marginRight: "1em",
      },
      "&:after": {
        content: "''",
        flex: 1,
        borderBottom: "1px solid #BBB",

        marginLeft: "1em",
      },
    },
  });

const ListHeader = withStyles(styles)(({ heading = "", classes }) => {
  return (
    <Typography variant="body2" className={classes.listHeader}>
      {heading}
    </Typography>
  );
});

class SearchPage extends Component {
  state = {
    searchText: "",
    filterValue: null,
  };

  render() {
    const { filterValue } = this.state;
    const { filteredProgramCatalog } = this.props.programCatalogStore;
    const { classes } = this.props;

    return (
      <IonPage>
        <Header title="Search" backButton={true} light />
        <IonContent className="content">
          <Container>
            <SearchHeader
              filterValue={filterValue}
              onChange={(value) => this.setState({ searchText: value })}
            />
          </Container>
          <Container>
            <ListHeader heading="Search Results" classes={this.props.classes} />
            <Link className={classes.link} to="/list">
              Go back to home page
            </Link>
            <div className={classes.gridList}>
              {filteredProgramCatalog &&
                filteredProgramCatalog.map((brand) => (
                  <div className={classes.gridTile} key={brand.id}>
                    <BrandDetail
                      brandId={brand.id}
                      brandName={brand.brand_name}
                      genericName={brand.generic_name}
                      specialty={brand.specialty}
                      ngo={brand.ngo}
                    />
                  </div>
                ))}
            </div>
            <div className={classes.separator}>End of Results</div>
          </Container>
        </IonContent>
      </IonPage>
    );
  }
}

const mapStateToProps = (state) => ({
  programCatalogStore: state.programCatalogStore,
});

const mapDispatchToProps = ({ programCatalogStore }) => ({
  ...programCatalogStore,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withMobileView(SearchPage)));
