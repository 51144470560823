import React, { Component } from "react";

import {
  Grid,
  TextField as TextInput,
  Typography,
  FormControl,
  InputAdornment,
  InputLabel,
  Input,
  IconButton,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { ErrorMessage } from "formik";

const styles = ({ spacing }) =>
  createStyles({
    formControl: {
      width: "100%",
    },
  });

class TextField extends Component {
  state = {
    showPassword: false,
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  render() {
    const { showPassword } = this.state;
    const {
      field,
      type,
      placeholder,
      label,
      disabled,
      helperText,
      upperCase,
      required,
      classes,
    } = this.props;

    return (
      <Grid container spacing={4} key={field.name}>
        <Grid item sm={12} xs={12}>
          {type === "password" ? (
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor={field.name}>
                {label ? `${label} ${required && "*"}` : placeholder}
              </InputLabel>
              <Input
                id={field.name}
                type={showPassword ? "text" : "password"}
                value={field.value || ""}
                onChange={field.onChange}
                onBlur={field.onBlur}
                onInput={field.onBlur}
                disabled={disabled}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          ) : (
            <TextInput
              type={type}
              label={label ? `${label} ${required && "*"}` : placeholder}
              value={field.value || ""}
              onChange={field.onChange}
              onBlur={field.onBlur}
              name={field.name}
              disabled={disabled}
              InputProps={{
                onInput: field.onBlur,
              }}
              onInput={
                upperCase &&
                ((e) => {
                  const start = e.target.selectionStart;
                  const end = e.target.selectionEnd;
                  e.target.value = e.target.value.toUpperCase();
                  e.target.setSelectionRange(start, end);
                })
              }
              helperText={helperText}
              fullWidth
            />
          )}

          <ErrorMessage
          name={field.name}
          render={(message) => {
            return (
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                color="error"
              >
                {message}
              </Typography>
            );
          }}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(TextField);
