import React from "react";
import { IonPage, IonContent } from "@ionic/react";
import { withMobileView } from "../../components/Mobile";
import Header from "../../common/Header";
import FAQContent from "./../../components/StaticContent/FAQContent";


const FAQPage = () => {
  return (
    <IonPage>
      <Header title="FAQ" />
      <IonContent className="content">
          <FAQContent/>
      </IonContent>
    </IonPage>
  );
};

export default withMobileView(FAQPage);
