import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  Select,
  FormControl,
  Checkbox,
  ListItemText,
  Input,
  MenuItem,
  withStyles,
  createStyles,
  Grid,
  Button,
  TextField,
  Typography,
  InputAdornment,
  Icon,
  Chip,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import SearchIcon from "@material-ui/icons/Search";
import { withMobileView } from "../../components/Mobile";

const CssTextField = withStyles(({ palette, spacing }) => ({
  root: {
    backgroundColor: "white",
    "& label.Mui-focused": {
      color: "#012169",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#012169",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#012169",
      },
      "&:hover fieldset": {
        borderColor: "#012169",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#012169",
      },
    },
  },
}))(TextField);

const styles = ({ spacing, palette }) =>
  createStyles({
    formControl: {
      width: "auto",
      fullWidth: true,
      display: "flex",
      wrap: "nowrap",
    },
    selectInput: {
      backgroundColor: "white",
      borderRadius: 4,
      position: "relative",
      border: "1px solid #012169",
      fontSize: 16,
      width: "auto",
    },
    selectRoot: { padding: "15.5px 14px" },
    selectDefault: {
      color: grey[500],
    },
    selectText: {
      color: palette.primary.dark,
    },
    selectMenu: {},
    searchTextField: {
      // border: "1px solid #012169",
      // borderRadius: 4,
      // borderColor: "#012169",\
    },
    searchButton: {
      padding: "15px 22px",
      backgroundColor: palette.primary.dark,
      "&:hover": {
        backgroundColor: "#00174a",
      },
      height: "100%",
    },
    listHeader: {
      marginTop: spacing(2),
      color: "#333333",
      fontWeight: 700,
    },
    listHeaderMobile: {
      marginTop: spacing(3),
      marginBottom: spacing(1),
      display: "block",
    },
    selectTextField: {
      marginRight: spacing(2),
      marginBottom: spacing(1),
      marginLeft: spacing(2),
    },
    inputAdornment: {
      paddingRight: spacing(3),
    },
    inputAdornmentText: {
      backgroundColor: palette.primary.main,
      color: "white",
      padding: spacing(1),
      display: "block",
      borderRadius: "50%",
      width: 24,
      height: 24,
      fontSize: 13,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    chip: {
      marginRight: spacing(1),
      marginTop: spacing(2),
      color: palette.primary.dark,
      borderColor: palette.primary.dark,
      backgroundColor: grey[300],
    },
    chipIcon: {
      color: palette.primary.dark,
      "&:hover": {
        color: palette.primary.dark,
      },
    },
    clearAllButton: {
      marginTop: spacing(2),
      fontSize: 12,
      color: grey[700],
    },
    endAdornmentContainter: {
      minWidth: "50px",
      minHeight: "50px",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      cursor: "pointer",
    },
    menuItem: {
      whiteSpace: "normal",
    },
  });

const ListHeader = ({ heading = "", classes = null, isMobileView = false }) => {
  return (
    <Typography
      variant={isMobileView ? "body2" : "h6"}
      className={isMobileView ? classes.listHeaderMobile : classes.listHeader}
    >
      {heading}
    </Typography>
  );
};

class SearchHeader extends Component {
  state = {
    TA_searchText: "",
    NGO_searchText: "",
    openTA: false,
    openNGO: false,
  };

  getParams() {
    let params = new URLSearchParams(this.props.location.search);
    let paramsGet = {};

    paramsGet.TA_selected = params.get("TA_selected")
      ? params.get("TA_selected").split(",")
      : [];
    paramsGet.NGO_selected = params.get("NGO_selected")
      ? params.get("NGO_selected").split(",")
      : [];
    paramsGet.searchText = params.get("searchText")
      ? params.get("searchText")
      : "";

    return paramsGet;
  }

  async componentDidMount() {
    //Get Params from URL then populate fields on mount
    let paramsGet = this.getParams();
    this.props.applySearchParams(paramsGet);
    //await this.props.getFilteredProgramCatalog(paramsGet);
  }

  handleTAChange = async (event) => {
    await this.props.applySearchParams({
      ...this.props.searchParams,
      TA_selected: event.target.value.filter(function (el) {
        return el != null;
      }),
    });
    await this.props.getFilteredProgramCatalog(this.props.searchParams);
  };

  handleNGOChange = async (event) => {
    await this.props.applySearchParams({
      ...this.props.searchParams,
      NGO_selected: event.target.value.filter(function (el) {
        return el != null;
      }),
    });
    await this.props.getFilteredProgramCatalog(this.props.searchParams);
  };

  handleSearchChange = async (event) => {
    await this.props.applySearchParams({
      ...this.props.searchParams,
      searchText: event.target.value,
    });
    await this.props.getFilteredProgramCatalog(this.props.searchParams);
  };

  handleTASearchChange = (event) => {
    this.setState({
      ...this.state,
      TA_searchText: event.target.value,
    });
  };

  handleNGOSearchChange = (event) => {
    this.setState({
      ...this.state,
      NGO_searchText: event.target.value,
    });
  };

  toggleTASelect = (event) => {
    this.setState({
      ...this.state,
      openTA: !this.state.openTA,
    });
  };

  toggleNGOSelect = (event) => {
    this.setState({
      ...this.state,
      openNGO: !this.state.openNGO,
    });
  };

  handleTADelete = async (item) => {
    await this.props.applySearchParams({
      ...this.props.searchParams,
      TA_selected: this.props.searchParams.TA_selected.filter(function (el) {
        return el !== item;
      }),
    });
    await this.props.getFilteredProgramCatalog(this.props.searchParams);
  };

  handleNGODelete = async (item) => {
    await this.props.applySearchParams({
      ...this.props.searchParams,
      NGO_selected: this.props.searchParams.NGO_selected.filter(function (el) {
        return el !== item;
      }),
    });
    await this.props.getFilteredProgramCatalog(this.props.searchParams);
  };

  clearAll = async () => {
    await this.props.applySearchParams({
      ...this.props.searchParams,
      TA_selected: [],
      NGO_selected: [],
    });
    await this.props.getFilteredProgramCatalog(this.props.searchParams);
  };

  handleSubmit = async (event) => {
    if (!this.props.location.pathname.indexOf("/search")) {
      this.props.history.replace({
        pathname: "/search",
        search: "?" + new URLSearchParams(this.props.searchParams).toString(),
      });
    } else {
      this.props.history.push({
        pathname: "/search",
        search: "?" + new URLSearchParams(this.props.searchParams).toString(),
      });
    }
    await this.props.getFilteredProgramCatalog(this.props.searchParams);
  };

  stopImmediatePropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  render() {
    const { ngoList, taList } = this.props.programCatalogStore;

    const { TA_selected, NGO_selected, searchText } = this.props.searchParams;

    const { TA_searchText, NGO_searchText, openTA, openNGO } = this.state;

    const { classes, isMobileView } = this.props;

    return (
      <React.Fragment>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ListHeader
              heading="One Platform Search"
              classes={classes}
              isMobileView={isMobileView}
            />
          </Grid>
          <Grid item xs={isMobileView ? 12 : 5}>
            <FormControl className={classes.formControl}>
              <CssTextField
                id="outlined-basic"
                label={searchText === "" ? "Enter Text Here" : ""}
                variant="outlined"
                value={searchText}
                InputLabelProps={{ shrink: false }}
                onChange={this.handleSearchChange}
                className={classes.searchTextField}
                color="primary"
              />
            </FormControl>
          </Grid>
          <Grid item xs={isMobileView ? 12 : 3}>
            <FormControl className={classes.formControl}>
              <Select
                classes={{
                  root: classes.selectRoot,
                  selectMenu: classes.selectMenu,
                }}
                multiple
                displayEmpty
                value={TA_selected}
                onChange={this.handleTAChange}
                input={
                  <Input
                    disableUnderline
                    className={classes.selectInput}
                    onKeyDown={this.stopImmediatePropagation}
                  />
                }
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      <Typography className={classes.selectDefault}>
                        Drug Therapeutic Area
                      </Typography>
                    );
                  }
                  return (
                    <Typography className={classes.selectText}>
                      {selected[0]}
                    </Typography>
                  );
                }}
                onKeyDown={this.stopImmediatePropagation}
                open={openTA}
                onClose={this.toggleTASelect}
                onOpen={this.toggleTASelect}
                endAdornment={
                  <div
                    className={classes.endAdornmentContainter}
                    onClick={this.toggleTASelect}
                  >
                    <InputAdornment
                      position="end"
                      className={classes.inputAdornment}
                    >
                      <Typography
                        color="primary"
                        className={classes.inputAdornmentText}
                      >
                        {TA_selected.length}
                      </Typography>
                    </InputAdornment>
                  </div>
                }
                MenuProps={{
                  MenuListProps: { disablePadding: true },
                  disableAutoFocusItem: true,
                }}
              >
                <FormControl className={classes.formControl}>
                  <TextField
                    className={classes.selectTextField}
                    id="standard-basic"
                    value={TA_searchText}
                    label={TA_searchText === "" ? "Enter Text Here" : ""}
                    InputLabelProps={{ shrink: false }}
                    onChange={this.handleTASearchChange}
                    onKeyDown={(e) => e.stopPropagation()}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    autoFocus={true}
                  />
                </FormControl>
                {taList.map((item) =>
                  item.toLowerCase().indexOf(TA_searchText.toLowerCase()) >=
                  0 ? (
                    <MenuItem
                      key={item}
                      value={item}
                      onKeyDown={this.stopImmediatePropagation}
                      className={classes.menuItem}
                    >
                      <Checkbox checked={TA_selected.indexOf(item) > -1} />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ) : (
                    ""
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={isMobileView ? 12 : 2}>
            <FormControl className={classes.formControl}>
              <Select
                classes={{
                  root: classes.selectRoot,
                  selectMenu: classes.selectMenu,
                }}
                multiple
                displayEmpty
                value={NGO_selected}
                onChange={this.handleNGOChange}
                size="small"
                input={
                  <Input
                    disableUnderline
                    placeholder="NGO"
                    className={classes.selectInput}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        className={classes.inputAdornment}
                      >
                        <Typography
                          color="primary"
                          className={classes.inputAdornmentText}
                        >
                          {NGO_selected.length}
                        </Typography>
                      </InputAdornment>
                    }
                  />
                }
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      <Typography className={classes.selectDefault}>
                        NGO
                      </Typography>
                    );
                  }
                  return (
                    <Typography className={classes.selectText}>
                      {selected[0]}
                    </Typography>
                  );
                }}
                onKeyDown={this.stopImmediatePropagation}
                open={openNGO}
                onClose={this.toggleNGOSelect}
                onOpen={this.toggleNGOSelect}
                endAdornment={
                  <div
                    className={classes.endAdornmentContainter}
                    onClick={this.toggleNGOSelect}
                  >
                    <InputAdornment
                      position="end"
                      className={classes.inputAdornment}
                    >
                      <Typography
                        color="primary"
                        className={classes.inputAdornmentText}
                      >
                        {NGO_selected.length}
                      </Typography>
                    </InputAdornment>
                  </div>
                }
                MenuProps={{
                  MenuListProps: { disablePadding: true },
                  disableAutoFocusItem: true,
                }}
              >
                <FormControl className={classes.formControl}>
                  <TextField
                    size="small"
                    className={classes.selectTextField}
                    id="standard-basic"
                    value={NGO_searchText}
                    label={NGO_searchText === "" ? "Enter Text Here" : ""}
                    InputLabelProps={{ shrink: false }}
                    onChange={this.handleNGOSearchChange}
                    onKeyDown={(e) => e.stopPropagation()}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    autoFocus={true}
                  />
                </FormControl>
                {ngoList.map((item) =>
                  item.toLowerCase().indexOf(NGO_searchText.toLowerCase()) >=
                  0 ? (
                    <MenuItem
                      key={item}
                      value={item}
                      className={classes.menuItem}
                    >
                      <Checkbox checked={NGO_selected.indexOf(item) > -1} />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ) : (
                    ""
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={isMobileView ? 12 : 2}>
            <FormControl className={classes.formControl}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                className={classes.searchButton}
                onClick={this.handleSubmit}
              >
                SEARCH
              </Button>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container>
          {!isMobileView ? (
            <Grid item xs={12}>
              {TA_selected.slice(0, 10).map((item) => {
                return (
                  <Chip
                    variant="outlined"
                    key={item}
                    label={item}
                    onDelete={() => this.handleTADelete(item)}
                    classes={{
                      root: classes.chip,
                      deleteIcon: classes.chipIcon,
                    }}
                  />
                );
              })}
              {NGO_selected.slice(
                0,
                10 - TA_selected.length > 0 ? 10 - TA_selected.length : 0
              ).map((item) => {
                return (
                  <Chip
                    key={item}
                    label={item}
                    onDelete={() => this.handleNGODelete(item)}
                    variant="outlined"
                    classes={{
                      root: classes.chip,
                      deleteIcon: classes.chipIcon,
                    }}
                  />
                );
              })}
              {TA_selected.length + NGO_selected.length > 10 ? (
                <Chip
                  variant="outlined"
                  classes={{ root: classes.chip, deleteIcon: classes.chipIcon }}
                  label={"..."}
                />
              ) : (
                ""
              )}
              {TA_selected.length + NGO_selected.length > 0 ? (
                <Button
                  className={classes.clearAllButton}
                  onClick={this.clearAll}
                >
                  Clear All
                </Button>
              ) : (
                ""
              )}
            </Grid>
          ) : (
            ""
          )}
          {isMobileView && TA_selected.length + NGO_selected.length > 0 && (
            <Button className={classes.clearAllButton} onClick={this.clearAll}>
              Clear All
            </Button>
          )}
        </Grid>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  programCatalogStore: state.programCatalogStore,
  searchParams: state.programCatalogStore.searchParams,
});

const mapDispatchToProps = ({ programCatalogStore }) => ({
  ...programCatalogStore,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(withMobileView(SearchHeader))));
