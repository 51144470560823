import React from "react";
import MobileContext from "./context";

const withMobileView = (Component) => {
	class WithMobileViewClass extends React.Component {
		render() {
			return (
				<MobileContext.Consumer>
					{(isMobileView) => {
						return <Component {...this.props} isMobileView={isMobileView} />;
					}}
				</MobileContext.Consumer>
			);
		}
	}
	return WithMobileViewClass;
};

export default withMobileView;
