import React, { Component } from "react";
import { connect } from "react-redux";
import { Field } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

import FormikForm from "../../components/FormikForm/FormikForm.component";
import { generateValidationSchema } from "../../utilities/validationSchema.utility";
import { CheckboxField } from "../FormFields";


class UserRegistration extends Component {
  async componentDidMount() {
    await this.props.getUserFields();
  }

  handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);

    try {
      const { history } = this.props;

      await this.props.postRegisterUser(values);

      history.push("/verify");
    } catch (err) {
      console.log(err);
    } finally {
      actions.setSubmitting(false);
    }
  };

  render() {
    const { userFields } = this.props.userStore;

    return (
      <FormikForm
        initialValues={{ agreement_check: false }}
        onSubmitHandler={this.handleSubmit}
        validationSchema={generateValidationSchema(userFields, {
          agreement_check: Yup.bool().oneOf(
            [true],
            "Please agree to the terms and conditions to proceed."
          ),
        })}
        formFields={userFields}
        customSubmitText="Create Your Account"
      >
        <Field
          name="agreement_check"
          component={CheckboxField}
          label={
            <div style={{ padding: 16, marginTop: 16 }}>
              <span>
                I hereby consent to the processing of my personal information in
                the manner described in the{" "}
                <Link to="/terms-and-conditions">
                  Terms and Conditions
                </Link>
              </span>
            </div>
          }
          required
        />
      </FormikForm>
    );
  }
}

const mapStateToProps = (state) => ({
  programStore: state.programStore,
  userStore: state.userStore,
});

const mapDispatchToProps = ({ programStore, userStore, uiStore }) => ({
  ...programStore,
  ...userStore,
  ...uiStore,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserRegistration);
