import React, { Component } from "react";
import { connect } from "react-redux";
import { IonPage, IonContent } from "@ionic/react";
import {
  Typography,
  Container,
  Card,
  CardContent,
  Grid,
  withStyles,
  createStyles,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Button,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

import Header from "../../common/Header";

// FORMS
import UpdatePassword from "../../components/Forms/UpdatePassword.form";
import UpdateProfile from "../../components/Forms/UpdateProfile.form";

const styles = ({ spacing, palette }) =>
  createStyles({
    listHeader: {
      marginTop: spacing(2),
      marginBottom: spacing(2),
      color: grey[700],
    },
    deactiveCard: {
      marginBottom: spacing(4),
    },
    deactivateHeader: {
      textAlign: "center",
    },
    deactivateMessage: {
      textAlign: "center",
    },
    deactivateButton: {
      textTransform: "capitalize",
    },
    dialogText: {
      textAlign: "center",
      color: "#000",
    },
  });

const ListHeader = withStyles(styles)(({ heading = "", classes }) => {
  return (
    <Typography variant="body2" className={classes.listHeader}>
      {heading}
    </Typography>
  );
});

class ProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogConfirmDeactivation: false,
      dialogDoneDeactivation: false,
    };
  }

  handleDialogConfirmDeactivation() {
    this.setState((prevState) => ({
      dialogConfirmDeactivation: !prevState.dialogConfirmDeactivation,
    }));
  }

  handleDialogDoneDeactivation() {
    this.setState((prevState) => ({
      dialogDoneDeactivation: !prevState.dialogDoneDeactivation,
    }));
  }

  async handleAccountDeactivation() {
    const { postDeactivateAccount } = this.props;

    await this.handleDialogConfirmDeactivation();

    await postDeactivateAccount();

    await this.handleDialogDoneDeactivation();
  }

  render() {
    const { classes, logoutUser } = this.props;
    const { dialogConfirmDeactivation, dialogDoneDeactivation } = this.state;

    return (
      <IonPage>
        <Header backButton={true} title="Profile" searchButton={false} light />
        <IonContent className="content">
          <Container>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <ListHeader heading="Edit Profile" />
                <Card>
                  <CardContent>
                    <UpdateProfile />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <ListHeader heading="Reset Password" />
                <Card>
                  <CardContent>
                    <UpdatePassword />
                  </CardContent>
                </Card>
                <Card className={classes.deactiveCard}>
                  <CardContent>
                    <Button
                      onClick={() => this.handleDialogConfirmDeactivation()}
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      Deactivate Account
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
          <Dialog
            open={dialogConfirmDeactivation}
            onClose={() => this.handleDialogConfirmDeactivation()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              className={classes.deactivateHeader}
            >
              <b>Deactivate Account</b>
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                className={classes.dialogText}
                classid="alert-dialog-description"
              >
                Do you really want to deactivate your account?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.handleDialogConfirmDeactivation()}
                color="primary"
                className={classes.deactivateButton}
              >
                Cancel
              </Button>
              <Button
                onClick={() => this.handleAccountDeactivation()}
                color="primary"
                variant="contained"
                className={classes.deactivateButton}
                autoFocus
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={dialogDoneDeactivation}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              className={classes.deactivateHeader}
            >
              <b>HKAPI</b>
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                className={classes.dialogText}
              >
                We are sorry to see you go. Your account has been deactivated.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={logoutUser}
                color="primary"
                variant="contained"
                className={classes.deactivateButton}
                autoFocus
              >
                Okay
              </Button>
            </DialogActions>
          </Dialog>
        </IonContent>
      </IonPage>
    );
  }
}

const mapStateToProps = (state) => ({
  userStore: state.userStore,
  authStore: state.authStore,
});

const mapDispatchToProps = ({ userStore, authStore }) => ({
  ...userStore,
  ...authStore,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ProfilePage));
