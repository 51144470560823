import React, { Component } from "react";
import { connect } from "react-redux";
import { IonPage, IonContent } from "@ionic/react";
import {
  Container,
  Button,
  Box,
  Typography,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { ExpandMore, ExpandLess } from "@material-ui/icons";

import { programCatalogService } from "../../services/index.service";

// FORMS
import UserRegistration from "../../components/Forms/UserRegistration.form";

//CONTENT
import IntroductionContent from "../../components/StaticContent/IntroductionContent";

//IMG
import BackgroundImg from "./../../img/login-mobile-long.png";
import LogoImg from "./../../img/logo.png";

const styles = ({ spacing, palette }) =>
  createStyles({
    container: {
      minHeight: "100vh",
      // backgroundImage: `url(${BackgroundImg})`,
      // backgroundRepeat: "no-repeat",
      // backgroundPosition: "top center",
      // backgroundSize: "contain",
    },
    header: {
      color: "white",
      textAlign: "center",
    },
    form: {
      paddingRight: spacing(2),
      paddingLeft: spacing(2),
      marginTop: spacing(2),
      marginBottom: spacing(2),
    },
    intro: {
      backgroundImage: `url(${BackgroundImg})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "bottom center",
      backgroundSize: "cover",
    },
    introBox: {
      marginTop: spacing(2),
      paddingRight: spacing(2),
      paddingLeft: spacing(2),
    },
    introText: {
      color: "white",
      flexWrap: "nowrap",
      display: "flex",
      overflow: "auto",
      width: "100%",
      height: "55vh",
      "&::-webkit-scrollbar": {
        width: 5,
      },
      "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#2D5BC0",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#3770ed",
      },
    },
    introHide: { display: "none" },
    buttonBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    introButton: {
      color: "white",
      display: "block",
      textAlign: "center",
      alignSelf: "center",
      paddingBottom: spacing(2),
    },
    buttonHide: {
      display: "none",
    },
    logo: {
      paddingTop: spacing(4),
      paddingRight: spacing(8),
      paddingLeft: spacing(8),
      paddingBottom: spacing(1),
    },
    helperText: {
      display: "block",
      width: "100%",
      textAlign: "center",
      borderBottom: "1px solid #000",
      lineHeight: "0.1em",
      marginTop: spacing(4),
      marginBottom: spacing(4),
    },
    helperTextInner: {
      background: "#fff",
      padding: "0 10px",
    },
    loginButton: {
      backgroundColor: palette.primary.dark,
      "&:hover": {
        backgroundColor: "#00174a",
      },
    },
    headerInfo: {
      color: "#2d5bc0",
      fontSize: "14px",
      textAlign: "center",
      marginBottom: "20px",
    },
  });

class LoginPage extends Component {
  state = {
    showRegisterForm: false,
    isIntroOpen: false,
  };

  handleLogin = () => {
    this.setState({ showRegisterForm: false });
    this.props.loginUser();
  };

  handleIntro = () => {
    this.setState({
      ...this.state,
      isIntroOpen: !this.state.isIntroOpen,
    });
  };

  render() {
    const { showRegisterForm } = this.state;
    const { classes } = this.props;

    return (
      <IonPage>
        <IonContent>
          <Container disableGutters className={classes.container}>
            <Box className={classes.intro}>
              <img src={LogoImg} className={classes.logo} alt="HKAPI logo" />
              <Typography
                variant="h6"
                color="primary"
                className={classes.header}
              >
                One Platform Directory
              </Typography>
              <Box className={classes.introBox}>
                <Box className={classes.buttonBox}>
                  <Button
                    className={
                      this.state.isIntroOpen
                        ? classes.buttonHide
                        : classes.introButton
                    }
                    onClick={this.handleIntro}
                  >
                    <Box>View Introduction</Box>
                    <Box>
                      <ExpandMore />
                    </Box>
                  </Button>
                </Box>
                <IntroductionContent
                  className={
                    this.state.isIntroOpen
                      ? classes.introText
                      : classes.introHide
                  }
                />
                <Box className={classes.buttonBox}>
                  <Button
                    className={
                      this.state.isIntroOpen
                        ? classes.introButton
                        : classes.buttonHide
                    }
                    onClick={this.handleIntro}
                  >
                    <Box>Hide Introduction</Box>
                    <Box>
                      <ExpandLess />
                    </Box>
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box className={classes.form}>
              <Button
                onClick={this.handleLogin}
                variant="contained"
                color="primary"
                size="large"
                className={classes.loginButton}
                fullWidth
              >
                Login
              </Button>
              <small className={classes.helperText}>
                <span className={classes.helperTextInner}>
                  New to One Platform Directory
                </span>
              </small>
              <div className={classes.headerInfo}>
                Register for your HKAPI account with your business email address
              </div>

              {!showRegisterForm && (
                <Button
                  onClick={() => this.setState({ showRegisterForm: true })}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                >
                  Create Your Account
                </Button>
              )}
              {showRegisterForm && (
                <UserRegistration history={this.props.history} />
              )}
            </Box>
          </Container>
        </IonContent>
      </IonPage>
    );
  }
}

const mapDispatchToProps = ({ authStore }) => ({
  ...authStore,
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(LoginPage));
