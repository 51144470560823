import React, { Component } from "react";
import queryString from "query-string";
import {
  Typography,
  withStyles,
  createStyles,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Grid,
} from "@material-ui/core";

import UserGuideVideo from "./UserGuideVideo";

const styles = ({ spacing, palette }) =>
  createStyles({
    dialogPaperDesktop: {
      minWidth: "70vw",
      maxWidth: "70vw",
    },
    dialogPaperMobile: {
      minWidth: "90vw",
      maxWidth: "90vw",
    },
    dialogContent: {
      display: "inline-table",
    },
  });

class UserGuideModal extends Component {
  state = {
    userGuideModal: false,
    skippedUserGuide: false,
  };

  componentDidMount() {
    this.checkNewUser();
  }

  componentDidUpdate() {
    this.checkNewUser();
  }

  checkNewUser = () => {
    const { locationString } = this.props;
    const { userGuideModal, skippedUserGuide } = this.state;
    const { new: isNewUser } = queryString.parse(locationString);

    if (isNewUser === "true" && !userGuideModal && !skippedUserGuide) {
      this.setState({
        userGuideModal: true,
      });
    }
  };

  handleUserGuideModal = () => {
    const { history } = this.props;
    this.setState({
      skippedUserGuide: true,
      userGuideModal: false,
    });

    history.replace("list");
  };

  render() {
    const { userGuideModal } = this.state;

    const { classes, mobileView = false } = this.props;

    return (
      <Dialog
        open={userGuideModal}
        classes={{
          paper: mobileView
            ? classes.dialogPaperMobile
            : classes.dialogPaperDesktop,
        }}
        fullWidth
      >
        <DialogTitle>
          <Typography variant="h6" color="primary">
            User Guide
          </Typography>
          <br />
          <UserGuideVideo />
        </DialogTitle>
        <DialogActions>
          <Grid container justify="center" alignItems="center">
            <Button color="primary" onClick={this.handleUserGuideModal}>
              <b>Skip</b>
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(UserGuideModal);
