import React, { useState } from "react";
import { connect } from "react-redux";
import { Markup } from "interweave";

import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  Divider,
  Box,
  createStyles,
  withStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

import { ExpandMore } from "@material-ui/icons";

import { withMobileView } from "../Mobile";

const styles = ({ spacing, palette }) =>
  createStyles({
    //TODO: fix border-top
    root: {
      marginBottom: spacing(2),
    },
    rounded: {
      borderRadius: 4,
    },
    icon: {
      marginRight: spacing(1),
    },
    formControl: {
      width: "100%",
      fullWidth: true,
      display: "flex",
    },
    fullWidth: {
      width: "100%",
    },
    title: {
      fontWeight: 600,
    },
    details: {
      "& ul": {
        margin: 0,
        paddingLeft: 24,
        listStyle: "none",
      },

      "& li": {
        textIndent: "-0.7em",
        "&::before": {
          content: "'• '",
          color: "#2D5BC0",
        },
      },
    },
    menuItem: {
      whiteSpace: "normal",
      maxWidth: 800,
    },
    select: {
      "& div": {
        whiteSpace: "normal",
      },
    },
  });

const ProgramMechanic = ({
  brandSupport = [],
  classes = {},
  isMobileView,
  children,
  programCatalogStore,
  applySelectedMechanics,
  scrollBottom,
}) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = () => (event, isExpanded) => {
    setExpanded(!expanded);
    scrollBottom();
  };

  const [optionsIncomeLevel, setOptionsIncomeLevel] = useState([]);
  const [patientType, setPatientType] = useState("");
  const [incomeType, setIncomeType] = useState("");
  const [mechanics, setMechanics] = useState([]);

  const handlePatientTypeChange = (event) => {
    setPatientType(event.target.value);
    setOptionsIncomeLevel(event.target.value.children);
    setMechanics([]);
    setIncomeType("");
    applySelectedMechanics({
      patientType: event.target.value?.label,
      incomeType: "",
      mechanics: [],
    });
    scrollBottom();
  };

  const handlePatientIncomeChange = (event) => {
    setIncomeType(event.target.value);
    setMechanics(event.target.value.children);
    applySelectedMechanics({
      patientType: programCatalogStore.selectedMechanics.patientType,
      incomeType: event.target.value?.label,
      mechanics: event.target.value.children,
    });
    scrollBottom();
  };

  return (
    <ExpansionPanel
      classes={{ root: classes.root, rounded: classes.rounded }}
      expanded={isMobileView ? expanded : true}
      onChange={isMobileView && handleChange()}
    >
      <ExpansionPanelSummary
        expandIcon={isMobileView && <ExpandMore />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography variant="h6" className={classes.title}>
          Program Details
        </Typography>
      </ExpansionPanelSummary>
      <Divider />
      {brandSupport && brandSupport.length > 0 && (
        <ExpansionPanelDetails>
          <Box className={classes.fullWidth}>
            <Typography variant="body2" color="primary">
              Please select a tier for more information
            </Typography>

            <FormControl className={classes.formControl}>
              <InputLabel id="patient-type-label">
                Select Patient Type
              </InputLabel>
              <Select
                labelId="patient-type-label"
                id="patient-type-select"
                renderValue={(item) => {
                  return (
                    <Markup content={item.label} className={classes.menuItem} />
                  );
                }}
                value={patientType}
                onChange={handlePatientTypeChange}
                className={classes.select}
              >
                {brandSupport.map((item) => (
                  <MenuItem
                    key={item}
                    value={item}
                    className={classes.menuItem}
                  >
                    <Markup content={item.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </ExpansionPanelDetails>
      )}
      {optionsIncomeLevel && optionsIncomeLevel.length > 0 && (
        <ExpansionPanelDetails>
          <Box className={classes.fullWidth}>
            <FormControl className={classes.formControl}>
              <InputLabel id="income-type-label">
                Select Financial Criteria
              </InputLabel>
              <Select
                labelId="income-type-label"
                id="income-select"
                renderValue={(item) => {
                  return (
                    <Markup content={item.label} className={classes.menuItem} />
                  );
                }}
                value={incomeType}
                onChange={handlePatientIncomeChange}
                className={classes.select}
              >
                {optionsIncomeLevel.map((item) => (
                  <MenuItem
                    key={item}
                    value={item}
                    className={classes.menuItem}
                  >
                    <Markup content={item.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </ExpansionPanelDetails>
      )}
      {mechanics && mechanics.length > 0 && (
        <ExpansionPanelDetails>
          <Box className={classes.fullWidth}>
            <Typography variant="body2" color="primary">
              Details:
            </Typography>
            <div className={classes.details}>
              {mechanics.map((mechanic) => (
                <Markup content={mechanic.label} />
              ))}
            </div>
          </Box>
        </ExpansionPanelDetails>
      )}
    </ExpansionPanel>
  );
};

const mapStateToProps = (state) => ({
  programCatalogStore: state.programCatalogStore,
});

const mapDispatchToProps = ({ programCatalogStore }) => ({
  ...programCatalogStore,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMobileView(withStyles(styles)(ProgramMechanic)));
