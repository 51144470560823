import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Interweave from "interweave";
import moment from "moment";

import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  Divider,
  Box,
  createStyles,
  CardMedia,
  Link,
  withStyles,
  Select,
  MenuItem,
} from "@material-ui/core";
import { ExpandMore, Mail, Phone, LocationOn } from "@material-ui/icons";

import { withMobileView } from "../Mobile";

const styles = ({ spacing, palette }) =>
  createStyles({
    root: {
      marginBottom: spacing(2),
    },
    rounded: {
      borderRadius: 4,
    },
    icon: {
      marginRight: 3,
      marginTop: -3,
    },
    details: {
      flexDirection: "column",
      display: "flex",
      margin: 24,
    },
    title: {
      fontWeight: 600,
    },
    detailTitle: {
      marginRight: 15,
    },
    detailsHeader: {
      color: "#2D5BC0",
      fontSize: 14,
      fontWeight: 500,
      marginBottom: 8,
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap-reverse",
    },
    detailsBody: {
      fontSize: 14,

      "& p": {
        margin: 0,
      },
      "& ul": {
        margin: 0,
        paddingLeft: 24,
        listStyle: "none",
      },
      "& li": {
        textIndent: "-0.7em",
        "&::before": {
          content: "'• '",
          color: "#2D5BC0",
        },
      },
    },
    link: {
      marginRight: 10,
    },
    detailsSecondary: {
      fontSize: 14,
      color: "grey",
    },
    number: {
      display: "flex",
      flexDirection: "column",
    },
    contact: {
      display: "flex",
      fontSize: 14,
      margin: "4px 0px",
      "& div": {
        marginRight: "5px",
      },
    },
  });

const mapDispatchToProps = ({ programCatalogStore }) => ({
  ...programCatalogStore,
});

const ContactContent = connect(
  null,
  mapDispatchToProps
)(
  ({
    title = "",
    body = "",
    contact_information = [],
    classes = {},
    applySelectedContactInfo,
  }) => {
    const [contact, setContact] = useState(contact_information[0]);

    const handleChange = (event) => {
      setContact(event.target.value);
      applySelectedContactInfo(event.target.value);
    };

    useEffect(() => {
      setContact(contact_information[0]);
    }, [contact_information]);

    return contact ? (
      <div className={classes.details}>
        <div className={classes.detailsHeader} color="primary" variant="body2">
          {title}
        </div>

        {contact ? (
          <React.Fragment>
            <div
              className={classes.detailsSecondary}
              color="primary"
              variant="body2"
            >
              Branch Name
            </div>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={contact}
              onChange={handleChange}
            >
              {contact_information.map((item) => (
                <MenuItem value={item} key={item.branch_name}>
                  {item.branch_name}
                </MenuItem>
              ))}
            </Select>

            <CardMedia
              component="img"
              alt="address"
              image={contact.address_image_url}
              title="address"
            />

            <div className={classes.contact}>
              <LocationOn
                color="primary"
                fontSize="small"
                className={classes.icon}
              />
              {contact.address}
            </div>

            <div className={classes.contact}>
              <div className={classes.icon}>
                <Mail color="primary" fontSize="small" />
              </div>
              <div>
                {contact.email !== "N/A" ? (
                  <Link
                    className={classes.link}
                    href={"mailto:" + contact.email}
                  >
                    {contact.email}
                  </Link>
                ) : (
                  <> {contact.email} </>
                )}
              </div>
              <div className={classes.icon}>
                <Phone color="primary" fontSize="small" />
              </div>
              <div className={classes.number}>
                {contact.contact_number !== "N/A" ? (
                  <>
                    {contact.contact_number.map((number) => (
                      <div key={number}>
                        <Link href={"tel:" + number.value}>
                          {number.label}
                          {number.label && number.label !== "" ? "- " : ""}
                          {number.value}
                        </Link>
                      </div>
                    ))}
                  </>
                ) : (
                  <> {contact.contact_number} </>
                )}
              </div>
            </div>
          </React.Fragment>
        ) : null}
      </div>
    ) : (
      ""
    );
  }
);

const DetailContent = ({
  title = "",
  title2 = null,
  body = "",
  classes = {},
}) => {
  return body ? (
    <div className={classes.details}>
      <div className={classes.detailsHeader} color="primary" variant="body2">
        <div className={classes.detailTitle}>{title}</div>
        {title2 && <div className={classes.lastUpdated}>{title2}</div>}
      </div>

      <div className={classes.detailsBody} variant="body2">
        <Interweave content={body} />
      </div>
    </div>
  ) : (
    ""
  );
};

const ProgramInformation = ({
  brandDetails = {},
  classes = {},
  isMobileView,
  children,
}) => {
  const [expanded, setExpanded] = React.useState(true);

  const handleChange = () => (event, isExpanded) => {
    setExpanded(!expanded);
  };

  console.log(brandDetails);

  return (
    <ExpansionPanel
      classes={{ root: classes.root, rounded: classes.rounded }}
      expanded={isMobileView ? expanded : true}
      onChange={isMobileView && handleChange()}
    >
      <ExpansionPanelSummary
        expandIcon={isMobileView && <ExpandMore />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography variant="h6" className={classes.title}>
          Program Information
        </Typography>
      </ExpansionPanelSummary>
      <Divider />
      <DetailContent
        title="Brand Name"
        title2={
          "Last Updated: " +
          moment(brandDetails.updated_at).format("DD MMM YYYY") +
          " | v" +
          (brandDetails.version_number ? brandDetails.version_number : "1.0")
        }
        body={brandDetails.brand_name}
        classes={classes}
      />
      <DetailContent
        title="Generic Name"
        body={brandDetails.generic_name}
        classes={classes}
      />
      <DetailContent
        title="Non-government Organization"
        body={brandDetails.ngo}
        classes={classes}
      />
      <DetailContent
        title="NGO Contact Details"
        body={brandDetails.ngo_contact_details}
        classes={classes}
      />
      <DetailContent
        title="Inclusion criteria"
        body={brandDetails.inclusion_criteria}
        classes={classes}
      />
      <DetailContent
        title="Method of application"
        body={brandDetails.method_of_application}
        classes={classes}
      />
      <DetailContent
        title="Specialty"
        body={brandDetails.specialty.join(", ")}
        classes={classes}
      />
      <DetailContent
        title="Require receipt"
        body={brandDetails.require_receipt}
        classes={classes}
      />
      <ContactContent
        title="Program contact information"
        contact_information={brandDetails.contact_information}
        classes={classes}
      />
    </ExpansionPanel>
  );
};

export default withMobileView(withStyles(styles)(ProgramInformation));
