import React from "react";
import { Typography, createStyles, withStyles } from "@material-ui/core";

const styles = ({ spacing, palette }) =>
  createStyles({
    header: {
      fontWeight: 700,
      marginBottom: spacing(2),
    },
  });

const AboutContent = ({ classes = {} }) => {
  return (
    <>
      <Typography variant="h6" color="primary" className={classes.header}>
        Introduction
      </Typography>
      <Typography variant="body2" paragraph>
        <b>Mission Statement:</b>
        <br />
        <br />
        <i>
          To drive the expedient access of innovative healthcare solutions for
          the people of Hong Kong and Macau with high ethical standards.
        </i>
        <br />
        <br />
        Formed in 1968, 
        <b>The Hong Kong Association of the Pharmaceutical Industry</b> has 33
        full members, including the world's top 20 companies engaged in the
        research and development of pharmaceuticals. Our member companies
        provide over 70 percent of the prescription medicines in Hong Kong.
        <br />
        <br />
        Updating members with important information in the local pharmaceutical
        market, the Association aims to improve the overall well-being of Hong
        Kong people by making recommendations on healthcare policies, as well as
        the relationship among our member companies, the government and the
        community.
        <br />
        <br />
        The Association has actively participated in the events of the local
        health community and regularly presented its views to the public on
        different healthcare issues such as Voluntary Health Insurance Scheme,
        Pharmacy and Poisons (Amendment) Ordinance 2015 and various
        pharmaceutical regulations.
      </Typography>
    </>
  );
};

export default withStyles(styles)(AboutContent);
