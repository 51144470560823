import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Box, createStyles, withStyles } from "@material-ui/core";
import { Field } from "formik";
import * as Yup from "yup";

import FormikForm from "../../components/FormikForm/FormikForm.component";
import { CodeInputField } from "../../components/FormFields";

const styles = ({ spacing, palette }) =>
  createStyles({
    button: {
      backgroundColor: palette.primary.dark,
      "&:hover": {
        backgroundColor: "#00174a",
      },
    },
    resendCodeTextBox: {
      marginTop: spacing(2),
      marginBottom: spacing(4),
      textAlign: "center",
    },
    resendCodeText: {
      fontSize: 14,
      "&:hover": {
        cursor: "pointer",
      },
    },
  });

class UserVerification extends Component {
  handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);

    try {
      const { history } = this.props;

      await this.props.postVerifyUser(values);

      history.push("/list?new=true");
    } catch (err) {
      console.log(err);
    } finally {
      actions.setSubmitting(false);
    }
  };

  render() {
    const verificationSchema = Yup.object().shape({
      verification_code: Yup.string()
        .matches("^.{8,}$", "Verification code must be 8 characters long")
        .required("Please input your verification code"),
    });

    const { classes } = this.props;

    return (
      <FormikForm
        buttonClassName={classes.button}
        buttonSize={this.props.buttonSize}
        initialValues={{}}
        onSubmitHandler={this.handleSubmit}
        validationSchema={verificationSchema}
        customSubmitText="Verify Email Address"
      >
        <Field
          name="verification_code"
          type="text"
          component={CodeInputField}
          placeholder="Verification Code"
          label="Enter Code"
          upperCase
          required
        />
        <Box className={classes.resendCodeTextBox}>
          <Link
            className={classes.resendCodeText}
            onClick={this.props.postResendVerification}
          >
            Resend Code
          </Link>
        </Box>
      </FormikForm>
    );
  }
}

const mapDispatchToProps = ({ programStore, userStore }) => ({
  ...programStore,
  ...userStore,
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(UserVerification));
