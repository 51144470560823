import React, { useEffect } from "react";
import { skipWhile, take } from "rxjs/operators";
import { AuthService } from "../services/AuthService";
import { AuthActions } from "ionic-appauth";
import Loader from "../components/Loader/Loader.component";
const LoginRedirect = (props) => {
  useEffect(() => {
    AuthService.Instance.AuthorizationCallBack(
      props.location.pathname + props.location.search
    );
    AuthService.Instance.authObservable
      .pipe(
        skipWhile(
          (action) =>
            action.action !== AuthActions.SignInSuccess &&
            action.action !== AuthActions.SignInFailed
        ),
        take(1)
      )
      .subscribe((action) => {
        if (action.action === AuthActions.SignInSuccess) {
          props.history.replace("list");
        } else {
          props.history.replace("login");
        }
      });
  }, []);
  return <Loader />;
};
export default LoginRedirect;
